import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Col, Input, Label, Row } from "reactstrap";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import NavigationFooter from "../NavigationFooter";
import Required from "../Required";
import { useQuery } from "react-query";
import { getCities, getCountries, getStates } from "../../../http/get/getApi";
import { transformData } from "../../../utils/Utils";

const Home = ({ toggle, currentState, formik, isVenderEditing, isViewOnly }) => {
  //fetching countries data
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-country"],
    queryFn: () => getCountries(),
  });

  //optiopns for countries dropdown
  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  let countryId = formik.values.country;
  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", countryId],
    queryFn: () => getStates({ id: countryId }),
  });

  //optiopns for states dropdown
  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  let stateId = formik.values.state;
  
  const {
    data: cities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useQuery({
    queryKey: ["get-states-by-country", stateId],
    queryFn: () => getCities({ id: stateId }),
    staleTime: 60000,
    cacheTime: 300000,
    retry: 1,
  });

  //optiopns for cities dropdown
  const citiesOptions = transformData({
    dataArray: cities?.data?.cities || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  const handelCountryDropdown = (e) => {
    formik.setFieldValue("country", e.value);
  };
  const handelStateDropdown = (e) => {
    formik.setFieldValue("state", e.value);
  };

  const handelCityDropdown = (e) => {
    formik.setFieldValue("city", e.value);
  };

  const handelValidation = (callbackFun) => {
    if (formik.values.phone_number !== "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.cell_phone !== "") {
      formik.setFieldTouched("cell_phone", true);
    }

    if (formik.values.zip_code !== "") {
      formik.setFieldTouched("zip_code", true);
    }

    // if (formik.values.country !== "") {
    //   formik.setFieldTouched("country", true);
    // }
    // if (formik.values.state !== "") {
    //   formik.setFieldTouched("state", true);
    // }

    // if (formik.values.city !== "") {
    //   formik.setFieldTouched("city", true);
    // }

    if (formik.errors.phone_number && formik.values.phone_number !== "") {
      toast.warning("Invalid home phone number.");
      return;
    }
    if (formik.errors.cell_phone && formik.values.cell_phone !== "") {
      toast.warning("Invalid cell  phone number.");
      return;
    }
    if (formik.errors.zip_code && formik.values.zip_code !== "") {
      toast.warning("Invalid zip-code.");
      return;
    }

    // if (formik.errors) {
    //   callbackFun(false);
    // } else {
    //   callbackFun(true);
    // }
    // if (

    //   formik.values.country !== "" &&
    //   formik.values.state !== "" &&
    //   formik.values.city !== ""
    // ) {
    //   callbackFun(true);
    // }
    callbackFun(true);
  };

  const handleInputChange = (field, e) => {
    const { value } = e.target;

    // Calculate word count for the specific field
    let wordCount = 0;
    if (value.trim() !== '') {
        wordCount = value.trim().split(/\s+/).length;
    }

    // Update the field's value and its word count in Formik
    formik.setFieldValue(field, value);
    formik.setFieldValue(`${field}Count`, wordCount);
};

  return (
    <>
      <Row className="p-2 py-0">
        {/* First Section */}
        <Col md={6} className="border p-3 bg-white">
          <Row className="g-2">
            <Col md={4}>
              <div>
                <Label for="Home">Home Phone</Label>
                <Input
                  readOnly={isViewOnly}
                  id="Home"
                  placeholder="Enter home phone"
                  type="number"
                  name="phone_number"
                  value={formik.values.phone_number}
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('phone_number', e)}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                {formik.errors.phone_number && formik.touched.phone_number && (
                  <p style={{ color: "red" }}> {formik.errors.phone_number}</p>
                )}
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Label for="Fax">Fax Phone</Label>
                <Input
                  readOnly={isViewOnly}
                  id="Fax"
                  placeholder="Enter fax phone"
                  type="number"
                  name="fax_phone"
                  value={formik.values.fax_phone}
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('fax_phone', e)}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Label for="Cell">Cell Phone</Label>
                <Input
                  readOnly={isViewOnly}
                  id="Cell"
                  placeholder="Enter cell phone"
                  type="number"
                  name="cell_phone"
                  value={formik.values.cell_phone}
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('cell_phone', e)}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                {formik.errors.cell_phone && formik.touched.cell_phone && (
                  <p style={{ color: "red" }}> {formik.errors.cell_phone}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12}>
          <div className="mt-4">
          <Label for="notes">Notes</Label>
          <textarea
            readOnly={isViewOnly}
            id="notes"
            value={formik.values.notes}
            // onChange={formik.handleChange}
            onChange={(e) => handleInputChange('notes', e)}
            name="notes"
            placeholder="Enter notes here"
            rows={3}
            className="form-control"
          />
        </div>
      </Col>
          </Row>
        </Col>
        {/* Second Section */}
        <Col md={6} className="border p-3 bg-white border-start-0">
          <Row className="g-2">
            <Col md={8}>
              <div>
                <Label for="address">Address</Label>
                <Input
                  readOnly={isViewOnly}
                  id="address"
                  placeholder="Enter address"
                  name="address_1"
                  value={formik.values.address_1}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('address_1', e)}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.address_1 && formik.touched.address_1 && (
                  <p style={{ color: "red" }}> {formik.errors.address_1}</p>
                )}
              </div>
            </Col>

            {/* <Col md={4}>
              <div>
                <Label for="city">City</Label>
                <Input
                  id="city"
                  placeholder="Enter city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.city && formik.touched.city && <p style={{ color: "red" }}> {formik.errors.city}</p>}
              </div>
            </Col> */}
            <Col md={4}>
              <div>
                <Label for="zipCode">Zip-code</Label>
                <Input
                  readOnly={isViewOnly}
                  id="zipCode"
                  placeholder="Enter zip-code"
                  name="zip_code"
                  type="number"
                  value={formik.values.zip_code}
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('zip_code', e)}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.zip_code && formik.touched.zip_code && (
                  <p style={{ color: "red" }}> {formik.errors.zip_code}</p>
                )}
              </div>
            </Col>
          </Row>

          <Row className="mt-2 g-2">
            <Col md={4}>
              <div>
                <Label for="country">Select Country</Label>
                <ReactSelect
                  disabled={isViewOnly}
                  options={countriesOptions}
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={(e) => handelCountryDropdown(e)}
                  onBlur={() => formik.setFieldTouched("country", true)}
                  invalid={formik.errors.country && formik.touched.country}
                />
                {formik.errors.country && formik.touched.country && (
                  <p style={{ color: "red" }}> {formik.errors.country}</p>
                )}
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Label for="state">Select State</Label>
                <ReactSelect
                  disabled={isViewOnly}
                  options={statesOptions}
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={(e) => handelStateDropdown(e)}
                  onBlur={() => formik.setFieldTouched("state", true)}
                  invalid={formik.errors.state && formik.touched.state}
                />
                {formik.errors.state && formik.touched.state && <p style={{ color: "red" }}> {formik.errors.state}</p>}
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Label for="city">Select City </Label>
                <ReactSelect
                  disabled={isViewOnly}
                  options={citiesOptions}
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={(e) => handelCityDropdown(e)}
                  onBlur={() => formik.setFieldTouched("city", true)}
                  invalid={formik.errors.city && formik.touched.city}
                />
                {formik.errors.city && formik.touched.city && <p style={{ color: "red" }}>{formik.errors.city}</p>}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {isViewOnly ? (
        ""
      ) : (
        <NavigationFooter
          toggle={toggle}
          currentState={currentState}
          lastPage={"2"}
          validationFunction={handelValidation}
          formik={formik}
          isVenderEditing={isVenderEditing}
        />
      )}
    </>
  );
};
export default Home;
