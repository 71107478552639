import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { ReactDataTable } from "../../components/Component";
import NavigationHeader from "../components/NavigationHeader";
import TableActionBar from "../components/TableActionBar";
import { getEventsList, getVenderList } from "../../http/get/getApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import { deleteVender } from "../../http/delete/deleteApi";
import { NotAvailable } from "../Client/ClientListPage";
import { DataContext } from "../../App";

const VenderListPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");

  const { activeTab, setActivetab } = useContext(DataContext);


  //get vender list
  const {
    data: venderList,
    isLoading: venderListLoading,
    isError: venderListIsError,
  } = useQuery({
    queryKey: ["get-vendor-list", pageNo, recordLimit, search],
    queryFn: () => getVenderList({ id: "", page: pageNo, limit: recordLimit, search: search }),
    staleTime: 500,
    cacheTime: 300000,
    retry: 1,
    // onSuccess: () => {
    //   queryClient.invalidateQueries(["get-vendor-list"]);
    // },
  });

  // useMutation to delete vender
  const {
    mutate: deleteVenderMutation,
    isLoading: isVenderDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-vender-by-Id"],
    mutationFn: (ID) => deleteVender({ vender_id: ID }),
    onSuccess: () => {
      queryClient.invalidateQueries(["get-vendor-list"]);
    },
  });
  const deleteVenderFromList = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteVenderMutation(value);
          venderList.data = venderList?.data?.venders?.filter((vend) => vend?.id !== value);
          // Swal.fire("Deleted!", "Your vender has been deleted.", "success");
          Swal.fire({
            title: "Deleted!",
            text: "Your vendor has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        queryClient.invalidateQueries(["get-vendor-list"]);
      });
    } catch (error) {
      console.error("Error deleting vender:", error);
    }
  };

  const isLoading = isVenderDeleting || venderListLoading; // combining 2 states to check for laoding state/for showing shimmer effect

  const tableHeaddings = [
    {
      name: "Sr. No.",
      // selector: (row, index) => index + 1,
      selector: (row, index) => (pageNo * recordLimit) + index + 1,
      maxWidth: "5px",
    },
    {
      name: "Username",
      selector: (row) => row.username || <NotAvailable />,
    },
    // {
    //   name: "Vendor Name",
    //   selector: (row) => `${row.first_name} ${row.last_name}` || <NotAvailable />,
    // },
    {
      name: "Email",
      selector: (row) => row.email || <NotAvailable />,
    },

    {
      name: "Contact No.",
      selector: (row) => row.business_phone || <NotAvailable />,
    },

    {
      name: "Actions",
      allowOverflow: true,
      cell: (row) => (
        <>
          <TableActionBar
            options1Icon="eye"
            options1TooleTipText="View"
            options2Icon="icon ni ni-edit"
            options2TooleTipText="Edit"
            options3Icon="icon ni ni-trash"
            options3TooleTipText="Delete"
            // options1onClick={() => navigate(`/vendor-list/create-vender?type=view&id=${row.id}&from=fromView`)}
            options1onClick={() => navigate(`/vendor-list/create-vender/preview?id=${row.id}&from=previewdetail`)}
            options2onClick={() => navigate(`/vendor-list/create-vender?type=edit&id=${row.id}&from=fromEdit`)}
            options3onClick={() => deleteVenderFromList(row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <NavigationHeader
         heading={"Vendor List"} 
         buttonInnerText={"Add Vendor"} 
         route={"/vendor-list/create-vender?page=vendor"} 
      />
      <section className="m-4 border p-3 bg-white">
        <ReactDataTable
          Loading={isLoading}
          data={Array.isArray(venderList?.data?.venders) ? venderList?.data?.venders : []}
          columns={tableHeaddings}
          pagination
          actions
          searchBy="username"
          pageNo={setPageNo}
          setSearch={setSearch}
        />
      </section>
    </>
  );
};
export default VenderListPage;
