import React from "react";
import {
  Input,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState, useEffect } from "react";
import { Icon, ReactDataTable } from "../../components/Component";
import NavigationFooter from "../components/NavigationFooter";
import ReactSelect from "react-select";
import Required from "../components/Required";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { getEmployeeList } from "../../http/get/getApi";
import { transformData } from "../../utils/Utils";
import Swal from "sweetalert2";

const EStaff = ({ toggleMain, mainCurrentTab, formik, disabled, isEventEditing, isViewOnly }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const AddEmployeeModal = () => {
    setIsOpen(!isOpen);
  };

  const handelValidation = (callbackFun) => {
    // if (formik.values.employees.length === 0) {
    //   toast.error("add atleast one employee");
    //   return;
    // }

    // if (formik.values.employee === "") {
    //   toast.error("empoloyee value is required!");
    //   return;
    // }
    // if (formik.values.wage === "") {
    //   toast.error("wage is required!");
    //   return;
    // }
    callbackFun(true);
  };

  //function to delete card
  function deleteCard(index, empName) {
    if (isViewOnly) {
      return;
    }
    const updatedEmployees = formik.values.employees.filter((_, i) => i !== index);
    formik.setFieldValue("employees", updatedEmployees);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedEmployees(selectedEmployees.filter((item) => item !== empName));

        Swal.fire("Deleted!", "Your employee has been deleted.", "success");
      }
    });
  }

  const tableHeaddings = [
    {
      name: "SR no.",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => `${row.empName}`,
    },
    {
      name: "Wage",
      selector: (row) => `$ ${row.empWage}`,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div disabled={isViewOnly} onClick={() => deleteCard(row.id - 1, row.empName)}>
            <Icon
              name="icon ni ni-trash"
              style={{
                fontSize: "20px",
                //  cursor: "pointer"
                cursor: isViewOnly ? "not-allowed" : "pointer",
                pointerEvents: isViewOnly ? "none" : "auto",
                color: isViewOnly ? "gray" : "inherit",
              }}
            ></Icon>
          </div>
        </>
      ),
    },
  ];

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    let empData = [];
    if (formik.values.employees && formik.values.employees.length > 0) {
      for (let i = 0; i < formik.values.employees.length; i++) {
        empData.push({
          id: i + 1,
          empName: formik.values.employees[i]?.name,
          empWage: formik.values.employees[i]?.wage,
        });
      }
    }
    setTableData(empData);
  }, [formik.values.employees]);

  const handelCreateEmployee = () => {
    navigate("/employee-list/create-employee?page=event");
  };
  return (
    <div class="tab-content">
      <div className="d-block d-lg-none w-auto">
        <ul className="d-flex g-3 justify-content-end">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu>
                <ul className="link-list-opt">
                  <li>
                    <DropdownItem tag="a" href="#links" onClick={(ev) => ev.preventDefault()}>
                      <Button
                        color="primary ms-0 "
                        onClick={handelCreateEmployee}
                        // disabled={disabled}
                        disabled={isViewOnly}
                      >
                        Create Employee
                      </Button>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem tag="a" href="#links" onClick={(ev) => ev.preventDefault()}>
                      <Button
                        color="primary"
                        onClick={AddEmployeeModal}
                        // disabled={disabled}
                        disabled={isViewOnly}
                      >
                        Assign Employees To Event
                      </Button>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
      <div className="py-0 d-none d-lg-block">
        {!disabled && (
          <div className="form-group d-flex justify-content-start mt-4">
            <Button
              color="primary m-1 ms-0"
              onClick={handelCreateEmployee}
              // disabled={disabled}
              disabled={isViewOnly}
            >
              Create Employee
            </Button>
            <Button
              color="primary m-1"
              onClick={AddEmployeeModal}
              // disabled={disabled}
              disabled={isViewOnly}
            >
              Assign Employees To Event
            </Button>
            <Modal size="lg" isOpen={isOpen} toggle={AddEmployeeModal}>
              <ModalHeader
                toggle={AddEmployeeModal}
                close={
                  <Button className="close" onClick={AddEmployeeModal}>
                    <Icon name="cross" />
                  </Button>
                }
              >
                Assign Employee
              </ModalHeader>
              <ModalBody>
                <AssignForm
                  formik={formik}
                  close={() => setIsOpen(false)}
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                />
              </ModalBody>
            </Modal>
          </div>
        )}
      </div>
      <div className="pt-2 pb-0">
        <h5>Assigned Employes</h5>
        <section className="mt-4 border p-3 bg-white">
          <ReactDataTable
            // loading={employeeListLoading}
            data={tableData}
            columns={tableHeaddings}
            pagination
            actions
            searchBy="empName"
          />
        </section>
      </div>

      {/* {renderCards()} */}
      {isViewOnly ? (
        ""
      ) : (
        <NavigationFooter
          toggle={toggleMain}
          currentState={mainCurrentTab}
          lastPage={"5"}
          validationFunction={handelValidation}
          isViewOnly={disabled}
          formik={formik}
          isEventEditing={isEventEditing}
        />
      )}
    </div>
  );
};
export default EStaff;

const AssignForm = ({ formik, close, selectedEmployees, setSelectedEmployees }) => {
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [wage, setWage] = useState(null);

  //fetching employeeList data
  const {
    data: employeeList,
    isLoading: employeeListLoading,
    isError: employeeListError,
  } = useQuery({
    queryKey: ["get-employee-list"],
    queryFn: () => getEmployeeList({ id: "", page: "", limit: "", search: "", emp_designation: "" }),
  });

  //options for react-select
  const employeeOptions = transformData({
    dataArray: employeeList?.data?.employees || [],
    label: "first_name",
    value: "id",
    isLoading: employeeListLoading,
  });

  const addnewrecord = (updateOptions) => {
    if (selectedEmp === null || wage === null) {
      toast.error("Fill All the fields");
      return;
    }
    for (let i = 0; i < selectedEmployees.length; i++) {
      if (selectedEmployees[i].name === selectedEmp.name) {
        toast.warning("This employee is already selected");
        return;
      }
    }

    const updatedEmployees = [...selectedEmployees, selectedEmp];
    setSelectedEmployees(updatedEmployees);

    setSelectedEmp(null);
    setWage(null);

    formik.setFieldValue("employees", [
      ...formik.values.employees,
      {
        id: selectedEmp.id,
        name: selectedEmp.name,
        wage,
      },
    ]);
    close();
  };

  return (
    <>
      <div>
        <Label for="employee">
          Employee
          <Required />
        </Label>
        <ReactSelect
          options={employeeOptions}
          id="employee"
          name="employee"
          onChange={(e) =>
            setSelectedEmp({
              id: e.value,
              name: e.label,
            })
          }
        />
        <Label for="wage" className="mt-2">
          Wage
          <Required />
        </Label>
        <div className="input-group">
          <div className="input-group-append">
            <span className="input-group-text">$</span>
          </div>

          <Input
            type="number"
            id="wage"
            name="wage"
            placeholder="Wage"
            onChange={(e) => setWage(e.currentTarget.value)}
          />
        </div>
      </div>
      <Button className="mt-2" type="button" color="primary" onClick={addnewrecord}>
        <span>Save</span>
        {/* <em class="icon ni ni-save"></em> */}
      </Button>
    </>
  );
};
