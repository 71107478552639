import Swal from "sweetalert2";
import { dataURLtoFile, getCookie, parseDateString } from "../../utils/Utils";
import { toast } from "react-toastify";

const baseUrl = "https://api.ipartydjcrm.com/api/v1";
// let token = process.env.REACT_APP_AUTH_TOKEN;

// edit API to edit event  details =================================
export const editEventDetails = async (value, id) => {
  let values = value;
  const formData = new FormData();

  formData.append("id", id);

  if (values.client_id) {
    formData.append("client_id", values.client_id);
  }

  if (values.event_name) {
    formData.append("event_name", values.event_name);
  }

  if (values.event_type) {
    formData.append("event_type", values.event_type);
  }

  if (values.startTime) {
    formData.append("start_date_time", parseDateString(values.startTime));
  }

  if (values.end_date_time) {
    formData.append("end_date_time", parseDateString(values.end_date_time));
  }

  if (values.venue_id) {
    formData.append("venue_id", values.venue_id);
  }

  if (values.addons) {
    formData.append("addons", JSON.stringify(values.addons));
  } else {
  }
  if (values.attire) {
    formData.append("attire", values.attire);
  }
  if (values.setup_time) {
    formData.append("setup_time", parseDateString(values.setup_time));
  }
  if (values.booked_date) {
    formData.append("booked_date", parseDateString(values.booked_date));
  }
  if (values.booking_status) {
    formData.append("booking_status", values.booking_status);
  }

  if (values.contract_due_date) {
    formData.append("contract_due_date", parseDateString(values.contract_due_date));
  }

  if (values.contract_sent_date) {
    formData.append("contract_sent_date", parseDateString(values.contract_sent_date));
  }
  if (values.intial_contract_date) {
    formData.append("intial_contract_date", parseDateString(values.intial_contract_date));
  }

  if (values.guest_count) {
    formData.append("guest_count", values.guest_count);
  }
  if (values.inquiry_source) {
    formData.append("inquiry_source", values.inquiry_source);
  }
  if (values.instant_quote_id) {
    formData.append("instant_quote_id", values.instant_quote_id);
  }
  if (values.internal_notes_booking) {
    formData.append("internal_notes", values.internal_notes_booking);
  }
  if (values.internal_notes_resources) {
    formData.append("internal_notes", values.internal_notes_resources);
  }
  if (values.next_action) {
    formData.append("next_action", values.next_action);
  }
  if (values.next_action_date) {
    formData.append("next_action_date", parseDateString(values.next_action_date));
  }
  if (values.intial_contract_date) {
    formData.append("intial_contract_date", parseDateString(values.intial_contract_date));
  }
  if (values.package_id) {
    formData.append("package_id", values.package_id);
  }
  if (values.retainer_value) {
    formData.append("retainer_value", values.retainer_value);
  }
  if (values.rfi_form_id) {
    formData.append("rfi_form_id", values.rfi_form_id);
  }
  if (values.sales_person_id) {
    formData.append("sales_person_id", values.sales_person_id);
  }
  if (values.secondaryVenue) {
    formData.append("secondary_venue_id", values.secondaryVenue);
  }

  if (values.employees) {
    formData.append("employees", JSON.stringify(values.employees));
  }
  if (values.scheduled_payment_2) {
    formData.append("scheduled_payment_2", parseDateString(values.scheduled_payment_2));
  }
  // if (values.scheduled_payment_2_value !== "Done" && values.scheduled_payment_2_value) {
  if (values.scheduled_payment_2_value) {
    formData.append("scheduled_payment_2_value", values.scheduled_payment_2_value);
  }
  if (values.scheduled_payment_3) {
    formData.append("scheduled_payment_3", parseDateString(values.scheduled_payment_3));
  }

  if (values.scheduled_payment_3_value !== "Done" && values.scheduled_payment_3_value) {
    formData.append("scheduled_payment_3_value", values.scheduled_payment_3_value);
  }
  if (values.package_overtime) {
    formData.append("overtime_rate_hourly", values.package_overtime);
  }
  if (values.overtimeRateHalfHourly) {
    formData.append("overtime_rate_half_hourly", values.overtimeRateHalfHourly);
  }
  if (values.travel_cost) {
    formData.append("travel_fee", values.travel_cost);
  }
  if (values.discount1) {
    formData.append("discount_1", values.discount1);
  }
  if (values.discount2) {
    formData.append("discount_2", values.discount2);
  }
  if (values.add_on_total) {
    formData.append("addon_total", values.add_on_total);
  }
  if (values.balanceDue) {
    formData.append("balance_due_after_retainer", values.balanceDue);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/events/update-event`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while editing event ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// edit API to edit package  details =================================
export const editPackageDetails = async (value, id) => {
  let values = value;
  
  const formData = new FormData();

  formData.append("package_id", id);

  if (values.package_name) {
    formData.append("package_name", values.package_name || "");
  }else if (!values.package_name && values.package_nameCount == 0) {
    formData.append("package_name", "");
  }
 
  if (values.package_price) {
    formData.append("package_price", values.package_price);
  }else if (!values.package_price && values.package_priceCount == 0) {
    formData.append("package_price", "");
  }

  if (values.package_deposite_price) {
    formData.append("package_deposite_price", values.package_deposite_price);
  }else if (!values.package_deposite_price && values.package_deposite_priceCount == 0) {
    formData.append("package_deposite_price", "");
  }

  if (values.package_overtime) {
    formData.append("package_overtime", values.package_overtime);
  }else if (!values.package_overtime && values.package_overtimeCount == 0) {
    formData.append("package_overtime", "");
  }

  if (values.package_duration) {
    formData.append("package_duration", values.package_duration);
  }

  if (values.description) {
    formData.append("description", values.description);
  }else if (!values.description && values.descriptionCount == 0) {
    formData.append("description", "");
  }

  // const base64 = values.package_file?.split(",");
  // if (values.package_file) {
  //   var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  //   if (base64regex.test(base64[1])) {
  //     const userImage = dataURLtoFile(values.package_file, "newUserImage");
  //     formData.append("package_file ", userImage);
  //   }
  // } else if (!values.package_file && values.imagePresent == 0) {
  //   formData.append("package_file ", "");
  // }

  if (values?.package_file) {

    const isPackageUrl = values.package_file.startsWith("https");

    if(!isPackageUrl){
 
      const userImage = dataURLtoFile1(values.package_file, `${id + "_PackageImage"}`);

      formData.append("package_file", userImage);
    }
    
  } else if (!values.package_file && values.imagePresent == 0) {
    formData.append("package_file", "");
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/package/update-package`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while editing package ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  if (res.status) {
    toast.success(res.message);
  } else {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: res.errors[0],
      focusConfirm: false,
    });
  }
  return res;
};

// edit API to edit venue  details =================================
export const editVenueDetails = async (value, id) => {
  let values = value;
  const formData = new FormData();

  formData.append("venue_id", id);

  if (values.venue_name) {
    formData.append("venue_name", values.venue_name);
  }else if (!values.venue_name && values.venue_nameCount == 0) {
    formData.append("venue_name", "");
  }

  if (values.address) {
    formData.append("address", values.address);
  }else if (!values.address && values.addressCount == 0) {
    formData.append("address", "");
  }

  if (values.city) {
    formData.append("city", values.city);
  }

  if (values.country) {
    formData.append("country", values.country);
  }

  if (values.state) {
    formData.append("state", values.state);
  }

  if (values.zip_code) {
    formData.append("zip_code", values.zip_code);
  }else if (!values.zip_code && values.zip_codeCount == 0) {
    formData.append("zip_code", "");
  }

  if (values.email_address) {
    formData.append("email_address", values.email_address);
  }else if (!values.email_address && values.email_addressCount == 0) {
    formData.append("email_address", "");
  }

  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  }else if (!values.phone_number && values.phone_numberCount == 0) {
    formData.append("phone_number", "");
  }

  if (values.office_telephone) {
    formData.append("office_telephone", values.office_telephone);
  }else if (!values.office_telephone && values.office_telephoneCount == 0) {
    formData.append("office_telephone", "");
  }

  if (values.distance) {
    formData.append("distance", values.distance);
  }else if (!values.distance && values.distanceCount == 0) {
    formData.append("distance", "");
  }

  if (values.travel_cost) {
    formData.append("travel_cost", values.travel_cost);
  }else if (!values.travel_cost && values.travel_costCount == 0) {
    formData.append("travel_cost", "");
  }

  if (values.notes) {
    formData.append("notes", values.notes);
  }else if (!values.notes && values.notesCount == 0) {
    formData.append("notes", "");
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/venue/update-venue`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while editing venue ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  if (res.status) {
    toast.success(res.message);
  } else {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: res.message,
      focusConfirm: false,
    });
  }
  return res;
};

// edit API to edit vender  details =================================
export const editVenderDetails = async (value, id) => {
  let values = value;

  const formData = new FormData();

  formData.append("vender_id", id);

  if (values.username) {
    formData.append("username", values.username);
  }

  if (values.first_name) {
    formData.append("first_name", values.first_name);
  }else if (!values.first_name && values.first_nameCount == 0) {
    formData.append("first_name", "");
  }

  if (values.last_name) {
    formData.append("last_name", values.last_name);
  }else if (!values.last_name && values.last_nameCount == 0) {
    formData.append("last_name", "");
  }

  if (values.email) {
    formData.append("email", values.email);
  }

  if (values.business_phone) {
    formData.append("business_phone", values.business_phone);
  }else if (!values.business_phone && values.business_phoneCount == 0) {
    formData.append("business_phone", "");
  }

  if (values.password) {
    formData.append("password", values.password);
  }

  // const base64 = values.files.split(",");
  if (values.files) {
    // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    const isVenderUrl = values.files.startsWith("https");
    if (!isVenderUrl) {
      const userImage = dataURLtoFile(values.files, "userImage");

      formData.append("files", userImage);
    }
  }else if (!values.files && values.imagePresent == 0) {
    formData.append("files", "");
  }

  if (values.company_name) {
    formData.append("company_name", values.company_name);
  }else if (!values.company_name && values.company_nameCount == 0) {
    formData.append("company_name", "");
  }

  if (values.website) {
    formData.append("website", values.website);
  }else if (!values.website && values.websiteCount == 0) {
    formData.append("website", "");
  }

  if (values.business_fax) {
    formData.append("business_fax", values.business_fax);
  }else if (!values.business_fax && values.business_faxCount == 0) {
    formData.append("business_fax", "");
  }

  if (values.business_address) {
    formData.append("business_address", values.business_address);
  }else if (!values.business_address && values.business_addressCount == 0) {
    formData.append("business_address", "");
  }

  if (values.business_city) {
    formData.append("business_city", values.business_city);
  }
  if (values.business_zip_code) {
    formData.append("business_zip_code", values.business_zip_code);
  }else if (!values.business_zip_code && values.business_zip_codeCount == 0) {
    formData.append("business_zip_code", "");
  }

  if (values.business_country) {
    formData.append("business_country", values.business_country);
  }
  if (values.business_state) {
    formData.append("business_state", values.business_state);
  }
  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  }else if (!values.phone_number && values.phone_numberCount == 0) {
    formData.append("phone_number", "");
  }

  if (values.fax_phone) {
    formData.append("fax_phone", values.fax_phone);
  }else if (!values.fax_phone && values.fax_phoneCount == 0) {
    formData.append("fax_phone", "");
  }

  if (values.cell_phone) {
    formData.append("cell_phone", values.cell_phone);
  }else if (!values.cell_phone && values.cell_phoneCount == 0) {
    formData.append("cell_phone", "");
  }

  if (values.address_1) {
    formData.append("address_1", values.address_1);
  }else if (!values.address_1 && values.address_1Count == 0) {
    formData.append("address_1", "");
  }

  if (values.city) {
    formData.append("city", values.city);
  }
  if (values.country) {
    formData.append("country", values.country);
  }
  if (values.state) {
    formData.append("state", values.state);
  }
  if (values.zip_code) {
    formData.append("zip_code", values.zip_code);
  }else if (!values.zip_code && values.zip_codeCount == 0) {
    formData.append("zip_code", "");
  }

  if (values.spouse_name) {
    formData.append("spouse_name", values.spouse_name);
  }
  if (values.birthday) {
    formData.append("dob", parseDateString(values.birthday));
  }
  if (values.anniversary) {
    formData.append("anniversary", parseDateString(values.anniversary));
  }
  if (values.notes) {
    formData.append("notes", values.notes);
  }else if (!values.notes && values.notesCount == 0) {
    formData.append("notes", "");
  }

  if (values.date_added_to_system) {
    formData.append("date_added_to_system", parseDateString(values.date_added_to_system));
  }
  if (values.is_this_vendor) {
    formData.append("is_this_vendor", values.is_this_vendor);
  }
  if (values.prefer_vendor) {
    formData.append("prefer_vendor", values.prefer_vendor);
  }

  if (values.is_owner) {
    formData.append("is_owner", values.is_owner);
  }
  if (values.representative_name) {
    formData.append("representative_name", values.representative_name);
  }else if (!values.representative_name && values.representative_nameCount == 0) {
    formData.append("representative_name", "");
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/vender/update-vender`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while editing vender ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// edit API to edit client  details =================================
export const editClientDetails = async (value, id) => {
  let values = value;
  console.log('values',values)
  const formData = new FormData();

  formData.append("id", id);

  if (values.username) {
    formData.append("username", values.username);
  }

    // formData.append("first_name", values.first_name || "");
  
  
  if (values.first_name) {
    formData.append("first_name", values.first_name);
  }else if (!values.first_name && values.first_nameCount == 0) {
    formData.append("first_name", "");
  }

  if (values.last_name) {
    formData.append("last_name", values.last_name);
  }else if (!values.last_name && values.last_nameCount == 0) {
    formData.append("last_name", "");
  }

  if (values.email) {
    formData.append("email", values.email);
  }

  if (values.work_phone_number) {
    formData.append("work_phone_number", values.work_phone_number);
  }else if (!values.work_phone_number && values.work_phone_numberCount == 0) {
    formData.append("work_phone_number", "");
  }

  // const base64 = values.files.split(",");
  if (values.files) {
    // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    const isUrl = values.files.startsWith("https");
    if (!isUrl) {
      const userImage = dataURLtoFile(values.files, "userImage");
      formData.append("files", userImage);
    }
  }else if (!values.files && values.imagePresent == 0) {
    formData.append("files", "");
  }

  if (values.password) {
    formData.append("password", values.password);
  }
  if (values.address_1) {
    formData.append("address_1", values.address_1);
  }else if (!values.address_1 && values.address_1Count == 0) {
    formData.append("address_1", "");
  }

  if (values.address_2) {
    formData.append("address_2", values.address_2);
  }else if (!values.address_2 && values.address_2Count == 0) {
    formData.append("address_2", "");
  }

  if (values.country) {
    formData.append("country", values.country);
  }

  if (values.state) {
    formData.append("state", values.state);
  }
  if (values.dob) {
    formData.append("dob", parseDateString(values.dob));
  }
  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  }else if (!values.phone_number && values.phone_numberCount == 0) {
    formData.append("phone_number", "");
  }

  if (values.zip_code) {
    formData.append("zip_code", values.zip_code);
  }else if (!values.zip_code && values.zip_codeCount == 0) {
    formData.append("zip_code", "");
  }

  if (values.city) {
    formData.append("city", values.city);
  }
  if (values.anniversary_date) {
    formData.append("anniversary_date", parseDateString(values.anniversary_date));
  }

  if (values.notes) {
    formData.append("notes", values.notes);
  }else if (!values.notes && values.notesCount == 0) {
    formData.append("notes", "");
  }

  if (values.client_type) {
    formData.append("client_type", values.client_type);
  }
  if (values.client_number) {
    formData.append("client_number", values.client_number);
  }
  if (values.planning_lockout) {
    formData.append("planning_lockout", values.planning_lockout);
  }
  if (values.organization_name) {
    formData.append("organization_name", values.organization_name);
  }
  if (values.password_change === 1) {
    formData.append("password_change", values.password_change);
  }
  if (values.all_portal_change === 2) {
    formData.append("all_portal_change", values.all_portal_change);
  }

  if (values.company_detail) {
    formData.append("company_detail", values.company_detail);
  }else if (!values.company_detail && values.company_detailCount == 0) {
    formData.append("company_detail", "");
  }

  if (values.organization_detail) {
    formData.append("organization_detail", values.organization_detail);
  }else if (!values.organization_detail && values.organization_detailCount == 0) {
    formData.append("organization_detail", "");
  }

  if (values.school_detail) {
    formData.append("school_detail", values.school_detail);
  }else if (!values.school_detail && values.school_detailCount == 0) {
    formData.append("school_detail", "");
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/client/update-client`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while editing client ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// edit API to edit employee  details =================================
export const editEmployeeDetails = async (value, id) => {
  let values = value;
  
  const formData = new FormData();

  formData.append("id", id);

  if (values.username) {
    formData.append("username", values.username);
  }

  if (values.first_name) {
    formData.append("first_name", values.first_name);
  }else if (!values.first_name && values.first_nameCount == 0) {
    formData.append("first_name", "");
  }

  if (values.last_name) {
    formData.append("last_name", values.last_name);
  }else if (!values.last_name && values.last_nameCount == 0) {
    formData.append("last_name", "");
  }

  if (values.email) {
    formData.append("email", values.email);
  }

  if (values.password) {
    formData.append("password", values.password);
  }
  if (values.address_1) {
    formData.append("address_1", values.address_1);
  }else if (!values.address_1 && values.address_1Count == 0) {
    formData.append("address_1", "");
  }

  if (values.address_2) {
    formData.append("address_2", values.address_2);
  }else if (!values.address_2 && values.address_2Count == 0) {
    formData.append("address_2", "");
  }

  if (values.country) {
    formData.append("country", values.country);
  }
  if (values.state) {
    formData.append("state", values.state);
  }
  if (values.dob) {
    formData.append("dob", parseDateString(values.dob));
  }
  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  }else if (!values.phone_number && values.phone_numberCount == 0) {
    formData.append("phone_number", "");
  }

  if (values.zip_code) {
    formData.append("zip_code", values.zip_code);
  }else if (!values.zip_code && values.zip_codeCount == 0) {
    formData.append("zip_code", "");
  }

  if (values.city) {
    formData.append("city", values.city);
  }

  // const base64 = values.files.split(",");
  if (values.files) {
    // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    const empUrl = values.files.startsWith("https");
    if (!empUrl) {
      const userImage = dataURLtoFile(values.files, "userImage");

      formData.append("files", userImage);
    }
  }else if (!values.files && values.imagePresent == 0) {
    formData.append("files", "");
  }

  if (values.emp_designation) {
    formData.append("emp_designation", values.emp_designation);
  }
  if (values.emp_number) {
    formData.append("emp_number", values.emp_number);
  }else if (!values.emp_number && values.emp_numberCount == 0) {
    formData.append("emp_number", "");
  }

  if (values.website) {
    formData.append("website", values.website);
  }else if (!values.website && values.websiteCount == 0) {
    formData.append("website", "");
  }

  if (values.skype_id) {
    formData.append("skype_id", values.skype_id);
  }else if (!values.skype_id && values.skype_idCount == 0) {
    formData.append("skype_id", "");
  }

  if (values.appointment_link) {
    formData.append("appointment_link", values.appointment_link);
  }else if (!values.appointment_link && values.appointment_linkCount == 0) {
    formData.append("appointment_link", "");
  }

  if (values.work_phone_number) {
    formData.append("work_phone_number", values.work_phone_number);
  }else if (!values.work_phone_number && values.work_phone_numberCount == 0) {
    formData.append("work_phone_number", "");
  }

  if (values.home_phone_number) {
    formData.append("home_phone_number", values.home_phone_number);
  }else if (!values.home_phone_number && values.home_phone_numberCount == 0) {
    formData.append("home_phone_number", "");
  }

  if (values.biography) {
    formData.append("biography", values.biography);
  }else if (!values.biography && values.biographyCount == 0) {
    formData.append("biography", "");
  }

  if (values.notes) {
    formData.append("notes", values.notes);
  }else if (!values.notes && values.notesCount == 0) {
    formData.append("notes", "");
  }

  if (values.start_hired_date) {
    formData.append("start_hired_date", parseDateString(values.start_hired_date));
  }
  // if (values.notes) {
  //   formData.append("notes", values.notes);
  // }
  if (values.emp_type) {
    formData.append("emp_type", values.emp_type);
  }
  if (values.emp_category) {
    formData.append("emp_category", values.emp_category);
  }
  // if (values.notes) {
  //   formData.append("notes", values.notes);
  // }
  if (values.began_profession) {
    formData.append("began_profession", values.began_profession);
  }
  if (values.no_event_done) {
    formData.append("no_event_done", values.no_event_done);
  }else if (!values.no_event_done && values.no_event_doneCount == 0) {
    formData.append("no_event_done", "");
  }

  if (values.emp_experience) {
    formData.append("emp_experience", values.emp_experience);
  }else if (!values.emp_experience && values.emp_experienceCount == 0) {
    formData.append("emp_experience", "");
  }

  if (values.emp_pay_rate) {
    formData.append("emp_pay_rate", values.emp_pay_rate);
  }else if (!values.emp_pay_rate && values.emp_pay_rateCount == 0) {
    formData.append("emp_pay_rate", "");
  }

  if (values.calculation_method) {
    formData.append("calculation_method", values.calculation_method);
  }
  if (values.hourly_rate) {
    formData.append("hourly_rate", values.hourly_rate);
  }else if (!values.hourly_rate && values.hourly_rateCount == 0) {
    formData.append("hourly_rate", "");
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/employee/update-employee`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while editing employee ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

// Post api to Send message to Client for Enquiry
export const sendMessage = async ({
  id,
  name,
  phone_number,
  email,
  enquiry_message,
  event_name,
  event_type,
  event_package,
  message,
  report_message,
}) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("name", name);
  formData.append("phone_number", phone_number);
  formData.append("email", email);
  formData.append("enquiry_message", enquiry_message);
  formData.append("event_name", event_name);
  formData.append("event_type", event_type);
  formData.append("event_package", event_package);
  if (message) {
    formData.append("reply", message);
  }
  formData.append("report_message", report_message);

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  const response = await fetch(`${baseUrl}/enquiry/update-enquiry`, requestOptions);

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const res = await response.json();
  return res;
};

// edit API to edit company details =================================
export const editCompanyDetails = async (value) => {
  let values = value;
  const formData = new FormData();

  // if (values.id) {
  //   formData.append("id", values.id);
  // }
  // if (values.username) {
  //   formData.append("username", values.username);
  // }
  if (values.first_name) {
    formData.append("first_name", values.first_name);
  } else if (!values.first_name && values.nameCount == 0) {
    formData.append("first_name", "");
  }

  if (values.last_name) {
    formData.append("last_name", values.last_name);
  } else if (!values.last_name && values.lastNameCount == 0) {
    formData.append("last_name", "");
  }
  // if (values.email) {
  //   formData.append("email", values.email);
  // }
  // if (values.contact_number) {
  //   formData.append("phone_number", values.contact_number);
  // }
  if (values.phone_number) {
    formData.append("phone_number", values.phone_number);
  } else if (!values.phone_number && values.contactNumberCount == 0) {
    formData.append("phone_number", "");
  }

  if (values.personal_phone_number) {
    formData.append("personal_phone_number", values.personal_phone_number);
  } else if (!values.personal_phone_number && values.personal_phone_number_count == 0) {
    formData.append("personal_phone_number", "");
  }

  // const base64 = values.files?.split(",");
  if (values.files) {
    // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    const isProfileUrl = values.files.startsWith("https");

    if (!isProfileUrl) {
    // if (base64regex.test(base64[1])) {
      const userImage = dataURLtoFile(values.files, "userImage");
      formData.append("profile_image_path", userImage);
    }
  } else if (!values.files && values.imagePresent == 0) {
    formData.append("profile_image_path", "");
  }

  if (values.password) {
    formData.append("password", values.password);
  }
  if (values.address_1) {
    formData.append("address_1", values.address_1);
  } else if (!values.address_1 && values.address1Count == 0) {
    formData.append("address_1", "");
  }

  if (values.address_2) {
    formData.append("address_2", values.address_2);
  } else if (!values.address_2 && values.address2Count == 0) {
    formData.append("address_2", "");
  }

  if (values.city) {
    formData.append("city", values.city);
  }

  if (values.country) {
    formData.append("country", values.country);
  }

  if (values.state) {
    formData.append("state", values.state);
  }

  if (values.zip_code) {
    formData.append("zip_code", values.zip_code);
  } else if (!values.zip_code && values.zipCodeCount == 0) {
    formData.append("zip_code", "");
  }

  if (values.latitude) {
    formData.append("latitude", values.latitude);
  } else if (!values.latitude && values.latitudeCount == 0) {
    formData.append("latitude", "");
  }

  if (values.longitude) {
    formData.append("longitude", values.longitude);
  } else if (!values.longitude && values.longitudeCount == 0) {
    formData.append("longitude", "");
  }

  if (values.uniqueness) {
    formData.append("uniqueness", values.uniqueness);
  } else if (!values.uniqueness && values.uniquenessCount == 0) {
    formData.append("uniqueness", "");
  }

  if (values.description) {
    formData.append("description", values.description);
  } else if (!values.description && values.desCount == 0) {
    formData.append("description", "");
  }

  if (values.experience) {
    formData.append("experience", values.experience);
  } else if (!values.experience && values.experienceCount == 0) {
    formData.append("experience", "");
  }

  if (values.website) {
    formData.append("website", values.website);
  } else if (!values.website && values.websiteCount == 0) {
    formData.append("website", "");
  }

  if (values.facebook) {
    formData.append("facebook", values.facebook);
  } else if (!values.facebook && values.facebookCount == 0) {
    formData.append("facebook", "");
  }

  if (values.instagram) {
    formData.append("instagram", values.instagram);
  } else if (!values.instagram && values.instagramCount == 0) {
    formData.append("instagram", "");
  }

  if (values.linkedin) {
    formData.append("linkedin", values.linkedin);
  } else if (!values.linkedin && values.linkedinCount == 0) {
    formData.append("linkedin", "");
  }

  if (values.tiktok) {
    formData.append("tiktok", values.tiktok);
  } else if (!values.tiktok && values.tiktokCount == 0) {
    formData.append("tiktok", "");
  }

  if (values.soundcloud) {
    formData.append("soundcloud", values.soundcloud);
  } else if (!values.soundcloud && values.soundcloudCount == 0) {
    formData.append("soundcloud", "");
  }

  if (values.mixcloud) {
    formData.append("mixcloud", values.mixcloud);
  } else if (!values.mixcloud && values.mixcloudCount == 0) {
    formData.append("mixcloud", "");
  }

  if (values.twitch) {
    formData.append("twitch", values.twitch);
  } else if (!values.twitch && values.twitchCount == 0) {
    formData.append("twitch", "");
  }

  // if (values.pinterest) {
  //   formData.append("pinterest", values.pinterest);
  // }else if(!values.pinterest && values.pinterestCount == 0){
  //   formData.append("pinterest", "");
  // }

  if (values.youtube_links && values.youtube_links.length > 0) {
    // console.log("values.youtube_links",values.youtube_links)
    values.youtube_links.forEach((linkItem, index) => {
      formData.append(`youtube_links[${index}][title]`, linkItem.title);
      formData.append(`youtube_links[${index}][link]`, linkItem.link);
    });
  }

  if (values.mixes_links && values.mixes_links.length > 0) {
    values.mixes_links.forEach((linkItem, index) => {
      formData.append(`mixes_links[${index}][mixtitle]`, linkItem.mixtitle);
      formData.append(`mixes_links[${index}][mixlink]`, linkItem.mixlink);
      formData.append(`mixes_links[${index}][mixestype]`, linkItem.mixesType);
    });
  }

  if (values.category) {
    if (values.category && values.category.length > 0) {
      formData.append("category", JSON.stringify(values.category));
    } else if (values.category || values.category.length == 0) {
      formData.append("category", null);
    }
  }

  if (values.services) {
    if (values.services && values.services.length > 0) {
      formData.append("services", JSON.stringify(values.services));
    } else if (values.services || values.services.length == 0) {
      formData.append("services", null);
    }
  }

  if (values.genres) {
    if (values.genres && values.genres.length > 0) {
      formData.append("genres", JSON.stringify(values.genres));
    } else if (values.genres || values.genres.length == 0) {
      formData.append("genres", null);
    }
  }

  if (values.vendors && values.vendors.length > 0) {
    values.vendors.forEach((vendor, index) => {
      formData.append(`vendor[${index}][vendorName]`, vendor.vendorName);
      formData.append(`vendor[${index}][mobileNumber]`, vendor.mobileNumber);
      formData.append(`vendor[${index}][vendorType]`, vendor.vendorType);
    });
  }

  // images

  if (values.images && Object.keys(values.images).length > 0) {
    Object.keys(values.images).forEach((category) => {
      const images = values.images[category];
      // console.log(`${category} images:`, images);

      if (Array.isArray(images)) {
        images.forEach((image, index) => {
          if (typeof image === "string" && image.startsWith("data:image")) {
            // Handle base64 images for newly added images
            try {
              const file = dataURLtoFile1(image, `${category}_image_${index}`);
              formData.append(`images[${category}][${index}]`, file);
            } catch (error) {
              console.error(`Error converting base64 image for ${category}:`, error);
            }
          }
          if (values.images && Object.keys(values.images).length > 0) {
            if (typeof image.url === "string" && image.url.startsWith("https://")) {
              formData.append(`image_urls[${category}][${index}]`, image.path);
            }
          } else {
            console.log(`Unsupported image format for ${category}`);
          }
        });
      } else {
        console.log(`Unsupported image format for ${category}`);
      }
    });
  } else {
    console.log("No images found in values.images");
  }

  // Mixes

  if (values.mixes && values.mixes.length > 0) {
    values.mixes.forEach((mixeFile, index) => {
      
      if (mixeFile instanceof File && mixeFile.type.startsWith("audio/")) {
        try {
          // Append the video File directly to FormData
          formData.append(`mixres[${index}]`, mixeFile);
        } catch (error) {
          console.error(`Error appending video file:`, error);
        }
      }
      if (typeof mixeFile.url === "string" && mixeFile.url.startsWith("https://")) {
        // formData.append(`mixres_urls[${index}]`, mixeFile.path);
        formData.append(`mixres_urls[${index}][path]`, mixeFile.path);
        formData.append(`mixres_urls[${index}][name]`, mixeFile.name);
      }
    });
  } else {
    console.log("No audio found in values.audio");
  }

  // videos

  if (values.videos && values.videos.length > 0) {
    values.videos.forEach((videoFile, index) => {

      if (videoFile instanceof File && videoFile.type.startsWith("video/")) {
        try {
          // Append the video File directly to FormData
          formData.append(`videos[${index}]`, videoFile);
        } catch (error) {
          console.error(`Error appending video file:`, error);
        }
      }

      if (typeof videoFile.url === "string" && videoFile.url.startsWith("https://")) {
        // formData.append(`videos_urls[${index}]`, videoFile.path);
        formData.append(`videos_urls[${index}][path]`, videoFile.path);
        formData.append(`videos_urls[${index}][name]`, videoFile.name);
        formData.append(`videos_urls[${index}][thumbnail]`, videoFile.thumbnail);
      }
    });
  } else {
    console.log("No videos found in values.videos");
  }

  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };

  // const response = await fetch(`${baseUrl}/account/update-company-profile`, requestOptions);
  const response = await fetch(`${baseUrl}/client/update-dj-profile`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while editing company profile ");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  const res = await response.json();
  return res;
};

function dataURLtoFile1(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function dataURLtoFile2(dataurl, filename) {
  // Extract the mime type from data URL
  const mime = dataurl.split(":")[1].split(";")[0]; // Extracting mime type (e.g., video/mp4)

  // Base64 to binary
  const byteString = atob(dataurl.split(",")[1]);

  // Create a Uint8Array to hold the binary data
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Create a Blob from the ArrayBuffer
  const blob = new Blob([ab], { type: mime });

  // Convert Blob to File by appending the filename
  return new File([blob], filename, { type: mime });
}
