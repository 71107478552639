import React, { useEffect, useState } from "react";
import { CardTitle} from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { getEventActivity, getEventType, getEventsList } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { PaginationComponent } from "../../components/Component";

const RecentActivity = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(5);

  // Fetch event types and event list
  const { data: eventType } = useQuery({
    queryKey: ["get-event-type"],
    queryFn: () => getEventType({ type: "1" }),
  });

  const { data: eventList } = useQuery({
    queryKey: ["get-event-list"],
    queryFn: () => getEventActivity(),
    staleTime: 5000,
    cacheTime: 300000, 
    retry: 1,
  });

  // Calculate total number of items and indices for pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  
  // Slice the event list data to display only the current page's items
  const currentItems = eventList?.data?.slice(indexOfFirstItem, indexOfLastItem) || [];
  
  // Pagination function to change the current page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let eventCategoryTotal = eventType?.data?.event_type?.length || 0;

  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <h4 className="me-2">Event Activities</h4>{" "}
              <div className="fs-14px fw-normal mt-3 text-body">
                Total Event Types: {eventList?.data?.length}
              </div>
            </h6>
          </CardTitle>
        </div>
      </div>

      <DataTableBody className="border-top h-100" bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow className="text-center">
            <span className="text-dark tb-lead">Sr. No.</span>
          </DataTableRow>
          <DataTableRow className="text-center">
            <span className="text-dark tb-lead">Event Name</span>
          </DataTableRow>
          <DataTableRow className="text-center">
            <span className="text-dark tb-lead">Event Count</span>
          </DataTableRow>
        </DataTableHead>
        
        {currentItems.map((item, idx) => (
          <DataTableItem key={idx}>
            <DataTableRow className="text-center">
              <span className="tb-sub">
                <a href="#order" className="text-dark">{indexOfFirstItem + idx + 1}</a>
              </span>
            </DataTableRow>
            <DataTableRow className="text-center">
              <span className="tb-sub text-dark">{item?.event_type_title}</span>
            </DataTableRow>
            <DataTableRow className="text-center">
              <span className="tb-sub text-dark">{item?.event_count}</span>
            </DataTableRow>
          </DataTableItem>
        ))}
      </DataTableBody>

      <div className="my-3 mx-auto">
        <PaginationComponent
          itemPerPage={itemPerPage}
          totalItems={eventList?.data?.length || 0}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </React.Fragment>
  );
};

export default RecentActivity;
