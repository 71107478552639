import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { ReactDataTable } from "../../components/Component";
import { getVenueList } from "../../http/get/getApi";
import NavigationHeader from "../components/NavigationHeader";
import TableActionBar from "../components/TableActionBar";
import { NotAvailable } from "../Client/ClientListPage";
import { ShimmerTable } from "react-shimmer-effects";
import { deleteVenue } from "../../http/delete/deleteApi";
import Swal from "sweetalert2";

const VenueListPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");

  const {
    data: venueList,
    isLoading: venueIsLoading,
    isError: venueListIsError,
  } = useQuery({
    queryKey: ["get-venue-list", pageNo, recordLimit, search],
    queryFn: () => getVenueList({ id: "", page: pageNo, limit: recordLimit, search: search }),
    // onSuccess: () => {
    //   queryClient.invalidateQueries(["get-venue-list"]);
    // },
  });

  // useMutation to delete venue
  const {
    mutate: deleteVenueMutation,
    isLoading: isVenueDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-venue-by-Id"],
    mutationFn: (ID) => deleteVenue({ venue_id: ID }),
    onSuccess: (data) => {
      if (data.status && !hasInvalidated) {
        queryClient.invalidateQueries(["get-venue-list"]);
        setHasInvalidated(true);
      }
    },
  });
  const deleteVenueFromList = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteVenueMutation(value);
          venueList.data = venueList?.data?.list?.filter((place) => place?.id !== value);
          Swal.fire({
            title :"Deleted!",
            text : "Your venue has been deleted.",
             icon : "success",
             showConfirmButton: false,
             timer: 2000,
          });
        }
        queryClient.invalidateQueries(["get-venue-list"]);
      });

      console.log("venue deleted successfully!");
    } catch (error) {
      console.error("Error deleting venue:", error);
    }
  };

  const isLoading = isVenueDeleting || venueIsLoading;

  const tableHeaddings = [
    {
      name: "Sr. No.",
      // selector: (row, index) => index + 1,
      selector: (row, index) => (pageNo * recordLimit) + index + 1,
      maxWidth: "5px",
    },
    {
      name: "Venue Name",
      selector: (row) => row.venue_name || <NotAvailable />,
    },
    {
      name: "Address",
      selector: (row) => row.address || <NotAvailable />,
    },
    // {
    //   name: "City",
    //   selector: (row) => row.city || <NotAvailable />,
    // },
    {
      name: "Zip-Code",
      selector: (row) => row.zip_code || <NotAvailable />,
    },
    {
      name: "Email",
      selector: (row) => row.email_address || <NotAvailable />,
    },
    {
      name: "Contact No.",
      selector: (row) => row.phone_number || <NotAvailable />,
    },

    {
      name: "Actions",
      allowOverflow: true,
      cell: (row) => (
        <>
          <TableActionBar
            options1Icon="eye"
            options1TooleTipText="View"
            options2Icon="icon ni ni-edit"
            options2TooleTipText="Edit"
            options3Icon="icon ni ni-trash"
            options3TooleTipText="Delete"
            // options1onClick={() => navigate(`/venue-list/create-venue?type=view&id=${row.id}&from=fromView`)}
            options1onClick={() => navigate(`/venue-list/create-venue/preview?id=${row.id}&from=previewdetail`)}
            options2onClick={() => navigate(`/venue-list/create-venue?type=edit&id=${row.id}&from=fromEdit`)}
            options3onClick={() => deleteVenueFromList(row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <NavigationHeader heading={"Venue List"} buttonInnerText={"Add Venue"} route={"/venue-list/create-venue"} />
      <section className="m-4 border p-3 bg-white">
        <ReactDataTable
          loading={isLoading}
          data={venueList?.data?.list || []}
          columns={tableHeaddings}
          pagination
          actions
          searchBy="venue_name"
          pageNo={setPageNo} // sending function to change page no
          activePage={venueList?.data?.page || 0} // sending the current page no
          totalRecords={venueList?.data?.totalRecords || 10} // sending the total no of records om database
          recordLimit={setRecordLimit} //setting up value of no of records to display
          setSearch={setSearch} // send search value setFunction
        />
      </section>
    </>
  );
};
export default VenueListPage;
