import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import AccountInformation from "../components/CreateClientPageForm/AccountInformation";
import EventPlaning from "../components/CreateClientPageForm/EventPlaning";
import General from "../components/CreateClientPageForm/General";
import Notes from "../components/CreateClientPageForm/Notes";
import NavigationHeader from "../components/NavigationHeader";
import { useLocation, useNavigate } from "react-router";
import { getClientsList } from "../../http/get/getApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addClient } from "../../http/post/postApi";
import { editClientDetails } from "../../http/edit/editApi";
import { PlanContext } from "../context/PlanContext";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { getChangedValues } from "../../utils/Utils";

const CreateClientPage = () => {
  const { planState, setPlanState } = useContext(PlanContext);
  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);

  // navigation declrations
  const location = useLocation();
  const navigate = useNavigate();

  //getting details from the url
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  const page = searchParams.get("page");

  useEffect(() => {
    if (type === null) {
      formik.setValues(initialValues);
    }
  }, [type]);

  const isEditOnly = type === "edit" ? true : false;
  const isViewOnly = type === "view" ? true : false;
  const [activeTab, setActivetab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActivetab(tab);
    }
  };

  const schema = Yup.object().shape({
    // first_name: Yup.string().required("First name field is required."),
    username: Yup.string().required("Username field is required."),
    // country: Yup.string().required("Country field is required."),
    // state: Yup.string().required("State field is required."),
    // city: Yup.string().required("City field is required."),
    // last_name: Yup.string().required("Last name field is required."),
    // work_phone_number: Yup.string()
    //   .required("Work mobile no field is required.")
    //   .matches(/^[0-9]{9,13}$/, "Work mobile no must be between 9 to 13 digits."),
    // phone_number: Yup.string()
    //   .nullable()
    //   .matches(/^[0-9]{9,13}$/, "Home phone no must be between 9 to 13 digits."),
    email: Yup.string().required("Email field is required.").email("Invalid email address."),
    password: isEditOnly
      ? Yup.string()
          .nullable()
          .min(8, "Password must be at least 8 characters.")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
          )
      : Yup.string()  
          .required("Password is required.")
          .min(8, "Password must be at least 8 characters.")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
          ),
    // zip_code: Yup.string()
    //   .nullable()
    //   .matches(/^[a-zA-Z0-9]{5,6}$/, "Zipcode must be betwen 5 to 6 digits."),
  });

  let initialValues = {
    work_phone_number: "",
    anniversary_date: "",
    notes: "",
    notesCount: "",
    organization_name: "",
    client_type: "",
    client_number: "",
    planning_lockout: "",
    email: "",
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    phone_number: "",
    address_1: "",
    address_2: "",
    country: "",
    state: "",
    dob: "",
    files: "",
    city: "",
    zip_code: "",
    password_change: "",
    all_portal_change: "",
    company_detail: "",
    organization_detail: "",
    school_detail: "",
    first_nameCount: "",
    last_nameCount: "",
    work_phone_numberCount: "",
    address_1Count: "",
    address_2Count: "",
    company_detailCount: "",
    organization_detailCount: "",
    school_detailCount: "",
    zip_codeCount: "",
    phone_numberCount: "",
    imagePresent : "",
  };

  const {
    data: clientsList,
    isLoading: clientsListIsLoading,
    isError: clientsListIsError,
  } = useQuery({
    queryKey: ["get-clients-by-id", id],
    queryFn: () => getClientsList({ id: id }),
    // onSuccess:()=>{
    //   queryClient.invalidateQueries("get-clients-by-id", id);
    // },
    staleTime: 5000,
    cacheTime: 300000, 
    retry: 1,
  });

  const [dataError, setDataError] = useState(true)
  //Mutation hook for adding client
  const {
    mutate: addNewClient,
    isLoading: isClientAdding,
    error: clientAddError,
  } = useMutation({
    mutationKey: ["Add-client"],
    mutationFn: (values) => addClient(values),
    onSuccess: (data) => {
    
      if (!data.errors && data.status) {
        // navigate(`/client-list/create-client?type=edit&id=${data.data[0].id}`);
        toast.success("Client Added Succesfully");
        navigate('/client-list');
        queryClient.invalidateQueries("get-clients-list");
      } 
      else if (data.errors) {
        setDataError(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.errors[0],
          focusConfirm: false,
        });
      } else {
        setDataError(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message,
          focusConfirm: false,
        });
      }
    },
  });
  

  const addnewClient = (values) => {
    try {
      if (planState === "true") {
        addNewClient(values);
      } else {
        navigate("/plan-selection");
      }
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };


  //Mutation hook for editing client details
  const {
    mutate: editClientMutation,
    isLoading: isClientEditing,
    error: clientEditError,
  } = useMutation({
    mutationKey: ["Edit-client-by-ID"],
    mutationFn: (value) => editClientDetails(value,id),
    onSuccess: (data) => {
      if (!data.errors && data.status) {
        
        queryClient.invalidateQueries(["get-clients-by-id", id]);
        setHasInvalidated(true);
        if (activeTab === "1") {
          // if (window.history.length > 1) {
            if (from === "fromEdit" ) {
              if (from === "fromEdit") {
                toast.success("Client Updated Succesfully");
                navigate('/client-list');
              } else {
                toast.success("Client Added Succesfully");
                navigate('/client-list');
              }
            } else {
              // navigate(-2);
              toast.success("Client Added Succesfully");
              navigate('/client-list');
            }
          // }
          // queryClient.invalidateQueries("get-clients-by-id",id);
          queryClient.invalidateQueries("get-clients-list");
        }
      }
      queryClient.invalidateQueries(["get-clients-list"]);
    },
  });

  //function for editing client details
  const editClientFromList = (value) => {
    try {
      editClientMutation(value);
    } catch (error) {
      console.error("Error editing client:", error);
    }
  };

  useEffect(() => {
    if (!clientsListIsLoading && isEditOnly || isViewOnly) {
      formik.setValues((prevData) => {
        return {
          ...prevData,
          ...clientsList?.data?.clients[0],
          country: parseInt(clientsList?.data?.clients[0]?.country),
          state: parseInt(clientsList?.data?.clients[0]?.state),
          city: parseInt(clientsList?.data?.clients[0]?.city),
          files: clientsList?.data?.clients[0]?.profile_image_path,
          work_phone_number: clientsList?.data?.clients[0]?.work_phone_number,
        };
      });
    }
  }, [clientsListIsLoading, clientsList]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value) => {
      if (!isEditOnly) {
        addnewClient(value);
      } else if (isEditOnly) {
        // editClientFromList(value);
        editClientFromList(getChangedValues(clientsList?.data?.clients[0], formik.values));
      }
    },
  });


  return (
    <>
      <NavigationHeader
        heading={from === "fromEdit" ? "Edit client" : from === "fromView" ? "Client Details" : "Add Client"}
        buttonInnerText={"Back"}
        route={"/client-list"}
        back
      />
      <section className="m-4 mt-2 border p-3 pt-0 bg-white" style={{ height: "auto" }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "1" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("1");
              }}
            >
              General
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              // disabled
              disabled={!isViewOnly}
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "2" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("2");
              }}
            >
              Notes
            </NavLink>
          </NavItem> */}
          {/* <NavItem>
            <NavLink
              // disabled
              disabled={!isViewOnly}
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "2" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("2");
              }}
            >
              Account Information
            </NavLink>
          </NavItem> */}
          {/* <NavItem>
            <NavLink
              // disabled
              disabled={!isViewOnly}
              tag="a"
              href="#tab"
              className={classNames({ active: activeTab === "4" })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("4");
              }}
            >
              Event Planning
            </NavLink>
          </NavItem> */}
        </Nav>
        <form onSubmit={formik.handleSubmit} className="mt-3">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <General
                toggle={toggle}
                currentState={activeTab}
                formik={formik}
                isEditOnly={isEditOnly}
                isClientAdding={isClientAdding}
                clientsListIsLoading={clientsListIsLoading}
                isClientEditing={isClientEditing}
                isViewOnly={isViewOnly}
                dataError={dataError}
              />
            </TabPane>
            {/* <TabPane tabId="2">
              <Notes toggle={toggle} currentState={activeTab} formik={formik} isClientEditing={isClientEditing} isViewOnly={isViewOnly} />
            </TabPane> */}
            {/* <TabPane tabId="2">
              <AccountInformation toggle={toggle} currentState={activeTab} formik={formik} isClientEditing={isClientEditing} isViewOnly={isViewOnly}/>
            </TabPane> */}
            {/* <TabPane tabId="4">
              <EventPlaning
                toggle={toggle}
                currentState={activeTab}
                formik={formik}
                isClientEditing={isClientEditing}
                isViewOnly={isViewOnly}
              />
            </TabPane> */}
          </TabContent>
        </form>
      </section>
    </>
  );
};
export default CreateClientPage;
