// EClient.js

import React from "react";
import NavigationFooter from "../components/NavigationFooter";
import { Row, Col, Label, Button } from "reactstrap";
import Required from "../components/Required";
import { useNavigate } from "react-router";
import { Icon } from "../../components/Component";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import { getClientsList } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { transformData } from "../../utils/Utils";
import { ShimmerThumbnail } from "react-shimmer-effects";

const EClient = ({ toggleMain, mainCurrentTab, formik, isViewOnly, isEventAdding, isEventEditing }) => {
  const navigate = useNavigate();

  //fetching clientList data
  const {
    data: clientsList,
    isLoading: clientListLoading,
    isError: clientListError,
  } = useQuery({
    queryKey: ["get-clients-list"],
    queryFn: () => getClientsList({ id: "", page: "", limit: "", search: "" }),
    staleTime: 5000,
    cacheTime: 300000, 
    retry: 1,
  });

  function handleExistingClient(e) {
    formik.setFieldValue("client_id", e.value);
  }

  const handelValidation = (callbackFun) => {
    if (formik.values.client_id === "") {
      formik.setFieldTouched("client_id", true);
    }
    if (formik.values.client_id !== "") {
      callbackFun(true);
    }
  };

  //options for react select
  const options = transformData({
    dataArray: clientsList?.data?.clients || [],
    label: "first_name",
    value: "id",
    isLoading: clientListLoading,
  });

  return (
    <>
      <section>
        <Row className="p-2 py-0">
          <Col md={12} className="border p-3 bg-white">
            <Row className="row g-2">
              <Label for="existingClient">
                Select From Existing
                <Required />
              </Label>

              <Col sm="6" lg="7">
                <>
                  {clientListLoading ? (
                    <ShimmerThumbnail height={40} width={700} rounded />
                  ) : (
                    <ReactSelect
                      disabled={isViewOnly}
                      options={options}
                      id="client_id"
                      name="client_id"
                      value={formik.values.client_id}
                      onChange={(e) => handleExistingClient(e)}
                      onBlur={() => formik.setFieldTouched("client_id", true)}
                      invalid={formik.errors.client_id && formik.touched.client_id}
                    />
                  )}
                </>
                {formik.errors.client_id && formik.touched.client_id && (
                  <p style={{ color: "red" }}> {formik.errors.client_id}</p>
                )}
              </Col>
              <Col sm="6" lg="3">
              {
                isViewOnly ? "" :
                <Button
                  color="primary"
                  className="btn-dim"
                  outline
                  onClick={() => navigate("/client-list/create-client?page=event")}
                >
                  <Icon className="ni-plus"></Icon>
                  <span>Add New Client</span>
                </Button>
              }
              </Col>
            </Row>
          </Col>
        </Row>
        <NavigationFooter
          toggle={toggleMain}
          currentState={mainCurrentTab}
          lastPage={"5"}
          validationFunction={handelValidation}
          formik={formik}
          form="addEvent"
          isEventAdding={isEventAdding}
          isEventEditing={isEventEditing}
        />
      </section>
    </>
  );
};

export default EClient;
