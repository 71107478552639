import { useEffect } from "react";
import { useState } from "react";
import CustomReactSelect from "react-select";
import { Badge, Label } from "reactstrap";
import { useTheme } from "../../layout/provider/Theme";
import Required from "../../pages/components/Required";
import AddNewOpetionModal from "./AddNewOpetionModal";

const ReactSelect = ({
  value,
  options,
  id,
  name,
  onChange,
  onBlur,
  styles,
  placeholder,
  disabled = false,
  label,
  required,
  addOpetionsForm,
  api,
  postId,
  updateAttireOptions,
  updateEventTypeOptions,
  updateNextActionOptions,
}) => {
  const { skin } = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(options.find((option) => option.value === value));

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const customStyles = {
    // Style the container
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fff" : "#fff",  // Example dark mode background
      color: "black", // Change text color
      borderColor: state.isFocused ? "#60CF4F" : "#dbdfea",  // Change border color when focused
      boxShadow: state.isFocused ? "0 0 0 1px #60CF4F" : null,  // Highlight border on focus
    }),
    // Style for each option in the dropdown
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#60CF4F" // Change background color when option is selected
        : state.isFocused
        ? "#60CF4F" // Change background color when option is hovered
        : null,
      color: state.isSelected ? "#ffff" : "#000000", // Change text color for selected and hovered options
      padding: 10,
    }),
    // Style for the dropdown menu
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff", // Background of dropdown
    }),
  };

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      // border: "1px solid #384d69",
      borderColor: _state.isFocused ? "#dbdfea" : "#dbdfea",
      boxShadow: _state.isFocused ? "0 0 0 1px #60CF4F" : null,
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      // backgroundColor: "#141c26",
      backgroundColor: _state.isSelected
        ? "#60CF4F" // Change background color when option is selected
        : _state.isFocused
        ? "#60CF4F" // Change background color when option is hovered
        : null,
      color: _state.isSelected ? "#ffff" :  _state.isFocused ? "#000000" : "#fff", 
      // color: "white",
      border: "1px solid #dbdfea",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#141c26", // Background of dropdown
    }),
  };



  useEffect(() => {
    const selectedOpetions = options.find((option) => option.value === value);
    if (selectedOpetions === undefined) {
      setSelected(null);
    } else {
      setSelected(selectedOpetions);
    }
  }, [value]);

  return (
    <>
      {label && (
        <Label for={id} className="d-flex justify-content-between align-items-center">
          <div>
            {label}
            {required && <Required />}
          </div>
          {/* {addOpetionsForm && !disabled && (
            <Badge onClick={toggle} color="primary">
              + Add new
            </Badge>
          )} */}
        </Label>
      )}
      <CustomReactSelect
        options={options}
        id={id}
        name={name}
        value={selected}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        styles={skin === "dark" ? { ...darkModeStyles, ...styles } : customStyles}
      />

      <AddNewOpetionModal
        isOpen={isOpen}
        toggle={toggle}
        label={label}
        api={api}
        id={postId}
        updateAttireOptions={updateAttireOptions}
        updateEventTypeOptions={updateEventTypeOptions}
        updateNextActionOptions={updateNextActionOptions}
      />
    </>
  );
};

export default ReactSelect;
