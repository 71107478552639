import DatePicker from "react-datepicker";

import { toast } from "react-toastify";
import { Col, Input, Label, Row } from "reactstrap";
import { parseDateString } from "../../../utils/Utils";
import NavigationFooter from "../NavigationFooter";
import Required from "../Required";

const Notes = ({ toggle, currentState, formik, isViewOnly, isEmployeeEditing }) => {
  const handelValidation = (callbackFun) => {
    // if (formik.values.dob === "") {
    //   toast.error("Birthdate is required.");
    //   return;
    // }

    callbackFun(true);
  };

  const handleInputChange = (field, e) => {
    const { value } = e.target;

    // Calculate word count for the specific field
    let wordCount = 0;
    if (value.trim() !== '') {
        wordCount = value.trim().split(/\s+/).length;
    }

    // Update the field's value and its word count in Formik
    formik.setFieldValue(field, value);
    formik.setFieldValue(`${field}Count`, wordCount);
};

  return (
    <>
      <Row className="p-2 py-0">
        <Col md={6} className="border p-3 bg-white">
          <div>
            <Label for="biography">Biography</Label>
            <textarea
              readOnly={isViewOnly}
              as="textarea"
              id="biography"
              maxLength={255}
              placeholder="Write biography"
              rows={3}
              className="form-control"
              name="biography"
              value={formik.values.biography}
              // onChange={formik.handleChange}
              onChange={(e) => handleInputChange('biography', e)}
              onBlur={formik.handleBlur}
            />
          </div>
          {/* <div className="mt-4">
            <Label for="dob">Birthdate</Label>
            <DatePicker
              readOnly={isViewOnly}
              isClearable
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormatCalendar="MMMM"
              placeholderText="Select dob"
              id="dob"
              name="dob"
              maxDate={new Date()}
              dateFormat={"dd/MM/yy"}
              selected={formik.values.dob ? new Date(formik.values.dob) : ""}
              onChange={(date) => formik.setFieldValue("dob", date ? date.toISOString() : "")}
              className="form-control date-picker"
              onBlur={formik.handleBlur}
            />
            {formik.errors.dob && formik.touched.dob && <p style={{ color: "red" }}> {formik.errors.dob}</p>}
          </div> */}
        </Col>

        <Col md={6} className="border p-3 bg-white border-start-0">
          <div>
            <Label for="notes">Notes</Label>
            <textarea
              readOnly={isViewOnly}
              as="textarea"
              id="notes"
              maxLength={255}
              placeholder="Enter notes here"
              rows={3}
              className="form-control"
              name="notes"
              value={formik.values.notes}
              // onChange={formik.handleChange}
              onChange={(e) => handleInputChange('notes', e)}
              onBlur={formik.handleBlur}
            />
          </div>
        </Col>
      </Row>
      {isViewOnly ? (
        ""
      ) : (
        <NavigationFooter
          toggle={toggle}
          currentState={currentState}
          lastPage={"4"}
          validationFunction={handelValidation}
          formik={formik}
          isEmployeeEditing={isEmployeeEditing}
        />
      )}
    </>
  );
};
export default Notes;
