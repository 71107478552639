import { useEffect, useState } from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";
import NavigationFooter from "../NavigationFooter";

import UploadImage from "../UploadImage";
import Required from "../Required";
import { toast } from "react-toastify";
import ReactSelect from "../../../components/ReactSelect/ReactSelect";
import { useQuery } from "react-query";
import { transformData } from "../../../utils/Utils";
import { getCities, getCountries, getStates } from "../../../http/get/getApi";
import { Icon } from "../../../components/Component";
import { useParams } from "react-router";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Business = ({
  toggle,
  currentState,
  formik,
  isEditOnly,
  venderLoading,
  isVenderAdding,
  isVenderEditing,
  isViewOnly,
}) => {
  const { id } = useParams();

  //fetching countries data
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-country"],
    queryFn: () => getCountries(),
  });

  //optiopns for countries dropdown
  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  let countryId = formik.values.business_country;
  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", countryId],
    queryFn: () => getStates({ id: countryId }),
  });

  //optiopns for states dropdown
  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  let stateId = formik.values.business_state;

  const {
    data: cities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useQuery({
    queryKey: ["get-states-by-country", stateId],
    queryFn: () => getCities({ id: stateId }),
    staleTime: 60000,
    cacheTime: 300000,
    retry: 1,
  });

  //optiopns for cities dropdown
  const citiesOptions = transformData({
    dataArray: cities?.data?.cities || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  const handelCountryDropdown = (e) => {
    formik.setFieldValue("business_country", e.value);
  };
  const handelStateDropdown = (e) => {
    formik.setFieldValue("business_state", e.value);
  };

  const handelCityDropdown = (e) => {
    formik.setFieldValue("business_city", e.value);
  };

  const handelValidation = (callbackFun) => {
    if (formik.values.username === "") {
      formik.setFieldTouched("username", true);
    }
    // if (formik.values.first_name === "") {
    //   formik.setFieldTouched("first_name", true);
    // }

    // if (formik.values.last_name === "") {
    //   formik.setFieldTouched("last_name", true);
    // }

    if (formik.values.email === "") {
      formik.setFieldTouched("email", true);
    }

    // if (formik.values.business_country === "") {
    //   formik.setFieldTouched("business_country", true);
    // }
    // if (formik.values.business_state === "") {
    //   formik.setFieldTouched("business_state", true);
    // }
    // if (formik.values.business_city === "") {
    //   formik.setFieldTouched("business_city", true);
    // }

    if (formik.values.password === "" && !isEditOnly) {
      formik.setFieldTouched("password", true);
    }

    if (formik.values.business_phone === "") {
      formik.setFieldTouched("business_phone", true);
    }
    if (formik.errors.password && formik.values.password !== "" && !isEditOnly) {
      toast.warning("Invalid password.");
      return;
    }

    if (formik.errors.email && formik.values.email !== "") {
      toast.warning("Invalid email!");
      return;
    }

    if (formik.errors.business_phone && formik.values.business_phone !== "") {
      toast.warning("Invalid business phone!");
      return;
    }

    if (formik.errors.website && formik.values.website !== "") {
      toast.warning("Invalid website url !");
      return;
    }

    if (
      formik.values.username !== "" &&
      // formik.values.first_name !== "" &&
      // formik.values.last_name !== "" &&
      formik.values.email !== "" &&
      // formik.values.business_country !== "" &&
      // formik.values.business_state !== "" &&
      // formik.values.business_city !== "" &&
      (formik.values.business_phone !== "") & 
      (isEditOnly || formik.values.password !== "")
    ) {
      callbackFun(true);
    }
  };

  const handleInputChange = (field, e) => {
    const { value } = e.target;

    // Calculate word count for the specific field
    let wordCount = 0;
    if (value.trim() !== '') {
        wordCount = value.trim().split(/\s+/).length;
    }

    // Update the field's value and its word count in Formik
    formik.setFieldValue(field, value);
    formik.setFieldValue(`${field}Count`, wordCount);
};

  return (
    <>
      {venderLoading && isEditOnly ? (
        <Row>
          <Col md={6} className="border p-3 bg-white">
            <Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
              </Row>
            </Row>
            <Row className="m-3">
              <Col md={6}>
                <ShimmerThumbnail height={250} width={300} rounded />
                <ShimmerThumbnail height={40} width={300} rounded />
              </Col>
            </Row>
          </Col>
          <Col md={6} className="border p-3 bg-white">
            <Row>
              <Row className="mt-3">
                <Col md={6}>
                  <ShimmerThumbnail height={40} width={400} rounded />
                </Col>
                <Col md={6}>
                  <ShimmerThumbnail height={40} width={400} rounded />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <ShimmerThumbnail height={40} width={400} rounded />
                </Col>
                <Col md={6}>
                  <ShimmerThumbnail height={40} width={400} rounded />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
                <Col md={4}>
                  <ShimmerThumbnail height={40} width={250} rounded />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <ShimmerThumbnail height={40} width={400} rounded />
                </Col>
                <Col md={6}>
                  <ShimmerThumbnail height={40} width={400} rounded />
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="p-2 py-0">
          {/* First Section */}
          <Col md={5} className="border p-3 bg-white">
            <Row className="g-2">
              <Col md={6} className="mt-2">
                <UploadImage label="Upload profile picture" name="files" value={formik.values.files} formik={formik} isViewOnly={isViewOnly}/>
              </Col>
              <Col md={4}>
                
                <div className="mt-4">
                  <Label for="firstName">
                    First Name
                    {/* <Required /> */}
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    id="first_name"
                    placeholder="Enter first name"
                    name="first_name"
                    value={formik.values.first_name}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('first_name', e)}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.first_name && formik.touched.first_name}
                    autoComplete="off"
                  />
                  {/* {formik.errors.first_name && formik.touched.first_name && (
                    <p style={{ color: "red" }}> {formik.errors.first_name}</p>
                  )} */}
                </div>
                <div className="mt-4">
                  <Label for="lastName">
                    Last Name
                    {/* <Required /> */}
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    id="last_name"
                    placeholder="Enter last name"
                    name="last_name"
                    value={formik.values.last_name}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('last_name', e)}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.last_name && formik.touched.last_name}
                    autoComplete="off"
                  />
                  {/* {formik.errors.last_name && formik.touched.last_name && (
                    <p style={{ color: "red" }}> {formik.errors.last_name}</p>
                  )} */}
                </div>
                <div className="mt-4">
                  <Label for="comapnyName">Company Name</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="company_name"
                    placeholder="Enter Company name"
                    name="company_name"
                    value={formik.values.company_name}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('company_name', e)}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.errors.company_name && formik.touched.company_name && (
                    <p style={{ color: "red" }}> {formik.errors.company_name}</p>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="mt-2 g-2">
              {/* <Col md={4}>
               
              </Col> */}
              <Col md={6}>
                <div>
                  <Label for="website">Website</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="website"
                    placeholder="Enter website"
                    name="website"
                    value={formik.values.website}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('website', e)}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.errors.website && formik.touched.website && (
                    <p style={{ color: "red" }}> {formik.errors.website}</p>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          {/* Second Section */}
          <Col md={7} className="border p-3 bg-white border-start-0">
            <Row className="g-2">
              <Col md={5}>
                <div>
                  <Label for="email">
                    Email Address
                    <Required />
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    disabled={isEditOnly}
                    id="email"
                    type="email"
                    placeholder="Enter email address"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.email && formik.touched.email}
                    autoComplete="off"
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p style={{ color: "red" }}> {formik.errors.email}</p>
                  )}
                </div>
              </Col>
              <Col md={4}>
              <div>
                  <Label for="middleName">
                    Username
                    <Required />
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    disabled={isEditOnly}
                    id="username"
                    placeholder="Enter User name"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.username && formik.touched.username}
                    autoComplete="off"
                  />
                  {formik.errors.username && formik.touched.username && (
                    <p style={{ color: "red" }}> {formik.errors.username}</p>
                  )}
                </div>
              </Col>
              <Col md={3}>
                <div className="form-control-wrap">
                  <Label for="pass" className="d-flex justify-content-between align-items-center">
                    <div>
                      Password
                      <Required />
                    </div>
                  </Label>
                  <div className="form-icon form-icon-right mt-4">
                    <Icon
                      name={isPasswordVisible ? "ni ni-eye" : "ni ni-eye-off"}
                      className="icon mt-2"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Input
                    readOnly={isViewOnly}
                    type={isPasswordVisible ? "text" : "Password"}
                    id="password"
                    name="password"
                    className="form-control"
                    value={formik.values.password}
                    disabled={id ? true : false}
                    placeholder={isPasswordVisible ? "Enter password" : "● ● ● ● ● ● ●"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.password && formik.touched.password}
                    autoComplete="off"
                  />
                  {formik.errors.password && formik.touched.password && (
                    <p style={{ color: "red" }}> {formik.errors.password}</p>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="mt-2 g-2">
              <Col md={6}>
                <div>
                  <Label for="phone">
                    Business Phone
                    <Required />
                  </Label>
                  <Input
                    readOnly={isViewOnly}
                    id="phone"
                    type="number"
                    placeholder="Enter business phone"
                    name="business_phone"
                    value={formik.values.business_phone}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('business_phone', e)}
                    onBlur={formik.handleBlur}
                    invalid={formik.errors.business_phone && formik.touched.business_phone}
                    autoComplete="off"
                  />
                  {formik.errors.business_phone && formik.touched.business_phone && (
                    <p style={{ color: "red" }}> {formik.errors.business_phone}</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <Label for="fax">Business Fax</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="fax"
                    placeholder="Enter business fax"
                    name="business_fax"
                    value={formik.values.business_fax}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('business_fax', e)}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-2 g-2">
              <Col md={8}>
                <div>
                  <Label for="address">Business Address</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="address"
                    placeholder="Enter address"
                    name="business_address"
                    value={formik.values.business_address}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('business_address', e)}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.errors.business_address && formik.touched.business_address && (
                    <p style={{ color: "red" }}> {formik.errors.business_address}</p>
                  )}
                </div>
              </Col>

              {/* <Col md={4}>
                <div>
                  <Label for="city">City</Label>
                  <Input
                    id="city"
                    placeholder="Enter city"
                    name="business_city"
                    value={formik.values.business_city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.business_city && formik.touched.business_city && (
                    <p style={{ color: "red" }}> {formik.errors.business_city}</p>
                  )}
                </div>
              </Col> */}
              <Col md={4}>
                <div>
                  <Label for="zipCode">Zip-code</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="zipCode"
                    placeholder="Enter zip-code"
                    type="number"
                    name="business_zip_code"
                    value={formik.values.business_zip_code}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('business_zip_code', e)}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.errors.business_zip_code && formik.touched.business_zip_code && (
                    <p style={{ color: "red" }}> {formik.errors.business_zip_code}</p>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="mt-2 g-2">
              <Col md={4}>
                <div>
                  <Label for="country">
                    Select Country
                    {/* <Required /> */}
                  </Label>
                  <ReactSelect
                    disabled={isViewOnly}
                    options={countriesOptions}
                    id="country"
                    name="business_country"
                    value={formik.values.business_country}
                    onChange={(e) => handelCountryDropdown(e)}
                    onBlur={() => formik.setFieldTouched("business_country", true)}
                  />
                  {/* {formik.errors.business_country && formik.touched.business_country && (
                    <p style={{ color: "red" }}> {formik.errors.business_country}</p>
                  )} */}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Label for="state">
                    Select State
                    {/* <Required /> */}
                  </Label>
                  <ReactSelect
                    disabled={isViewOnly}
                    options={statesOptions}
                    id="business_state"
                    name="business_state"
                    value={formik.values.business_state}
                    onChange={(e) => handelStateDropdown(e)}
                    onBlur={() => formik.setFieldTouched("business_state", true)}
                  />
                  {/* {formik.errors.business_state && formik.touched.business_state && (
                    <p style={{ color: "red" }}> {formik.errors.business_state}</p>
                  )} */}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Label for="city">
                    Select City
                    {/* <Required /> */}
                  </Label>
                  <ReactSelect
                    disabled={isViewOnly}
                    options={citiesOptions}
                    id="business_city"
                    name="business_city"
                    value={formik.values.business_city}
                    onChange={(e) => handelCityDropdown(e)}
                    onBlur={() => formik.setFieldTouched("business_city", true)}
                    invalid={formik.errors.city && formik.touched.city}
                  />
                  {/* {formik.errors.business_city && formik.touched.business_city && (
                        <p style={{ color: "red" }}>{formik.errors.business_city}</p>
                      )} */}
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={4}>
                <div>
                  <Label for="spouse_name">Representive Name</Label>
                  <Input
                    readOnly={isViewOnly}
                    id="representative_name"
                    placeholder="Enter representative name "
                    name="representative_name"
                    value={formik.values.representative_name}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('representative_name', e)}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="d-flex align-items-center gap-4 mt-4">
                  <div className="d-flex align-items-center gap-2">
                    <input
                      disabled={isViewOnly}
                      type="checkbox"
                      name="owner"
                      id="checkbox1"
                      checked={formik.values.is_owner === 0}
                      onChange={() => formik.setFieldValue("is_owner", 0)}
                    />
                    <label htmlFor="checkbox1">Owner</label>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      disabled={isViewOnly}
                      type="checkbox"
                      name="employee"
                      id="checkbox1"
                      checked={formik.values.is_owner === 1}
                      onChange={() => formik.setFieldValue("is_owner", 1)}
                    />
                    <label htmlFor="checkbox2">Employee</label>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {isViewOnly ? (
        ""
      ) : (
        <NavigationFooter
          toggle={toggle}
          currentState={currentState}
          lastPage={"2"}
          validationFunction={handelValidation}
          formik={formik}
          isVenderAdding={isVenderAdding}
          isVenderEditing={isVenderEditing}
        />
      )}
    </>
  );
};
export default Business;
