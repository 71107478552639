import { useFormik } from "formik";
import { Col, Input, Label, Row } from "reactstrap";
import NavigationFooter from "../components/NavigationFooter";
import NavigationHeader from "../components/NavigationHeader";
import * as Yup from "yup";
import Required from "../components/Required";
import { useContext, useEffect, useState } from "react";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import { useLocation, useNavigate, useParams } from "react-router";
import { getCities, getCountries, getStates, getVenueList } from "../../http/get/getApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addVenue } from "../../http/post/postApi";
import { getChangedValues, transformData } from "../../utils/Utils";
import { editVenueDetails } from "../../http/edit/editApi";
import { PlanContext } from "../context/PlanContext";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { toast } from "react-toastify";

const CreateVenuePage = () => {
  const { planState, setPlanState } = useContext(PlanContext);
  // navigation declrations
  const location = useLocation();
  const navigate = useNavigate();
  const [verifyAllFields, setVerifyAllFields] = useState(false);

  const queryClient = useQueryClient();
  const [hasInvalidated, setHasInvalidated] = useState(false);

  //getting details from the url
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");

  useEffect(() => {
    if (type === null) {
      formik.setValues(initialValues);
    }
  }, [type]);

  const isEditOnly = type === "edit" ? true : false;
  const isViewOnly = type === "view" ? true : false;

  // setting up opetions for dropdown

  const schema = Yup.object().shape({
    venue_name: Yup.string().required("Venue name field is required."),
    address: Yup.string().required("Address field is required."),
    // city: Yup.string().required("City field is required."),
    // country: Yup.string().required("Country field is required."),
    // state: Yup.string().required("State field is required."),
    zip_code: Yup.string()
      .required("Zipcode is required.")
      .matches(/^[a-zA-Z0-9]{5,6}$/, "Zipcode must be betwen 5 to 6 digits."),
    email_address: Yup.string().nullable().email(" Invalid email address."),
    email_address: Yup.string().required("email address is required.").email(" Invalid email address."),
    // phone_number: Yup.string().nullable().matches(/^[0-9]{9,13}$/, " mobile no must be between 9 to 13 digits."),
    phone_number: Yup.string().nullable()
      .matches(/^[0-9]{9,13}$/, " mobile no must be between 9 to 13 digits."),
    office_telephone: Yup.string()
      .nullable()
      .matches(/^[0-9]{9,13}$/, " mobile no must be between 9 to 13 digits."),
  });

  //initial values for formik 
  let initialValues = {
    venue_name: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zip_code: "",
    email_address: "",
    phone_number: "",
    distance: "",
    office_telephone: "",
    travel_cost: "",
    venue_nameCount: "",
    addressCount: "",
    zip_codeCount: "",
    email_addressCount : "",
    phone_numberCount: "",
    distanceCount: "",
    office_telephoneCount: "",
    travel_costCount: "",
    notes: "",
    notesCount: "",
  };

  //fetching venue data from id
  const {
    data: venueData,
    isLoading: venueListLoading,
    isError: packageListIsError,
  } = useQuery({
    queryKey: ["get-venue-by-id", id],
    queryFn: () => getVenueList({ id: id }),
  });

  //Mutation hook for adding venue
  const {
    mutate: addNewVenue,
    isLoading: isVenueadding,
    error: venueAddError,
  } = useMutation({
    mutationKey: ["Add-venue"],
    mutationFn: (values) => addVenue(values),
    onSuccess: (data) => {
      if (!data.errors && data.status) {
        if (window.history.length > 1) {
          if (from === "fromEdit") {
            toast.success("Venue Updated Succesfully");
            navigate("/venue-list");
          } else {
            toast.success("Venue Added Succesfully");
            navigate("/venue-list");
          }
        }
      }
      queryClient.invalidateQueries(["get-venue-list"]);
    },
  });
  const addnewVenue = async (values) => {
    try {
      if (planState === "true") {
        addNewVenue(values);
      } else {
        navigate("/plan-selection");
      }
    } catch (error) {
      console.error("Error adding venue:", error);
    }
  };

  //Mutation hook for edit
  const {
    mutate: editVenueMutation,
    isLoading: isVenueEditing,
    error: venueEditError,
  } = useMutation({
    mutationKey: ["Edit-venue-by-ID"],
    mutationFn: (value) => editVenueDetails(value, id),
    onSuccess: (data) => {
      if (!data.errors && data.status && !hasInvalidated) {
        queryClient.invalidateQueries(["get-venue-by-id", id]);
        navigate("/venue-list");
        setHasInvalidated(true);
      }
      queryClient.invalidateQueries(["get-venue-list"]);
    },
  });
  const editVenueFromList = (value) => {
    try {
      editVenueMutation(value);
    } catch (error) {
      console.error("Error editing venue:", error);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (value) => {
      if (!isEditOnly) {
        await addnewVenue(value);
      } else if (isEditOnly) {
        // editVenueFromList(value);
        editVenueFromList(getChangedValues(venueData?.data?.list[0], formik.values));
      }
    },
  });

  //fetching countries data
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-country"],
    queryFn: () => getCountries(),
  });

  //optiopns for countries dropdown
  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  let countryId = formik.values.country;
  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", countryId],
    queryFn: () => getStates({ id: countryId }),
  });

  //optiopns for states dropdown
  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  let stateId = formik.values.state;

  const {
    data: cities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useQuery({
    queryKey: ["get-states-by-country", stateId],
    queryFn: () => getCities({ id: stateId }),
    staleTime: 60000,
    cacheTime: 300000,
    retry: 1,
  });

  //optiopns for cities dropdown
  const citiesOptions = transformData({
    dataArray: cities?.data?.cities || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  const handelCountryDropdown = (e) => {
    formik.setFieldValue("country", e.value);
  };
  const handelStateDropdown = (e) => {
    formik.setFieldValue("state", e.value);
  };

  const handelCityDropdown = (e) => {
    formik.setFieldValue("city", e.value);
  };

  //to set the values from the api when form is opened  edit type
  useEffect(() => {
    if (isEditOnly || isViewOnly) {
      formik.setValues((prevData) => {
        return {
          ...prevData,
          ...venueData?.data.list[0],
          country: parseInt(venueData?.data?.list[0]?.country),
          state: parseInt(venueData?.data?.list[0]?.state),
          city: parseInt(venueData?.data?.list[0]?.city),
        };
      });
    }
  }, [isEditOnly, venueListLoading, venueData]);

  useEffect(() => {
    if (
      formik.values.venue_name !== "" &&
      formik.values.address !== "" &&
      // formik.values.city !== "" &&
      // formik.values.country !== "" &&
      // formik.values.state !== "" &&
      formik.values.zip_code !== "" &&
      formik.values.email_address !== "" &&
      // formik.values.phone_number !== "" &&
      formik.values.distance !== ""
    ) {
      setVerifyAllFields(true);
    } else {
      setVerifyAllFields(false);
    }
  }, [formik.values]);

  const handelValidation = (callbackFun) => {
    if (formik.values.venue_name === "") {
      formik.setFieldTouched("venue_name", true);
    }
    if (formik.values.address === "") {
      formik.setFieldTouched("address", true);
    }

    if (formik.values.zip_code === "") {
      formik.setFieldTouched("zip_code", true);
    }

    if (formik.values.email_address === "") {
      formik.setFieldTouched("email_address", true);
    }

    // if (formik.values.phone_number === "") {
    //   formik.setFieldTouched("phone_number", true);
    // }

    // if (formik.values.country === "") {
    //   formik.setFieldTouched("country", true);
    // }

    // if (formik.values.state === "") {
    //   formik.setFieldTouched("state", true);
    // }

    // if (formik.values.city === "") {
    //   formik.setFieldTouched("city", true);
    // }

    if (formik.errors.email && formik.values.email !== "") {
      toast.warning("Invalid email.");
      return;
    }

    if (formik.errors.zip_code && formik.values.zip_code !== "") {
      toast.warning("Invalid zip_code.");
      return;
    }
    // if (formik.errors.phone_number && formik.values.phone_number !== "") {
    //   toast.warning("Invalid work phone number !");
    //   return;
    // }

    if (
      formik.values.venue_name !== "" &&
      formik.values.address !== "" &&
      // formik.values.city !== "" &&
      // formik.values.country !== "" &&
      // formik.values.state !== "" &&
      formik.values.zip_code !== "" &&
      formik.values.email_address !== ""
      // formik.values.phone_number !== ""
      // formik.values.distance !== ""
    ) {
      callbackFun(true);
    }
  };

  //event listener  to stop the scrolling behavior
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number" && document.activeElement.classList.contains("noscroll")) {
      document.activeElement.blur();
    }
  });

  const handleInputChange = (field, e) => {
    const { value } = e.target;

    // Calculate word count for the specific field
    let wordCount = 0;
    if (value.trim() !== '') {
        wordCount = value.trim().split(/\s+/).length;
    }

    // Update the field's value and its word count in Formik
    formik.setFieldValue(field, value);
    formik.setFieldValue(`${field}Count`, wordCount);
};

  return (
    <>
      <NavigationHeader
        heading={from === "fromEdit" ? "  Edit Venue " : from === "fromView" ? "Venue Details" : "Add Venue"}
        buttonInnerText={"Back"}
        route={"/venue-list"}
        back
      />

      {venueListLoading && isEditOnly ? (
        <div className="p-4">
          <Row>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Row className="mt-3">
                  <Col>
                    <ShimmerThumbnail height={40} width={800} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <ShimmerThumbnail height={40} width={400} rounded />
                  </Col>
                  <Col md={6}>
                    <ShimmerThumbnail height={40} width={400} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={250} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <ShimmerThumbnail height={40} width={800} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={800} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <section className="m-4 mt-2 border p-3 pt-0 bg-white" style={{ height: "auto" }}>
          <Row className="p-2 pb-0">
            {/* First Section */}
            <Col md={6} className="border p-3  bg-white">
              <div>
                <Label for="venue">
                  Venue Name
                  <Required />
                </Label>
                <Input
                  readOnly={isViewOnly}
                  id="venue_name"
                  placeholder="Venue name"
                  name="venue_name"
                  value={formik.values.venue_name}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('venue_name', e)}
                  onBlur={formik.handleBlur}
                  invalid={formik.errors.venue_name && formik.touched.venue_name}
                  autoComplete="off"
                />
                {formik.errors.venue_name && formik.touched.venue_name && (
                  <p style={{ color: "red" }}> {formik.errors.venue_name}</p>
                )}
              </div>

              <Row className="mt-2 g-2">
                <Col md={6}>
                  <div>
                    <Label for="address1">
                      Address
                      <Required />
                    </Label>
                    <Input
                      readOnly={isViewOnly}
                      id="address1"
                      placeholder="Address"
                      name="address"
                      value={formik.values.address}
                      // onChange={formik.handleChange}
                      onChange={(e) => handleInputChange('address', e)}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.address && formik.touched.address}
                    />
                    {formik.errors.address && formik.touched.address && (
                      <p style={{ color: "red" }}> {formik.errors.address}</p>
                    )}
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Label for="zipcode">
                      Zip-code
                      <Required />
                    </Label>
                    <Input
                      readOnly={isViewOnly}
                      id="zip_code"
                      type="number"
                      placeholder="Enter zip code"
                      name="zip_code"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      value={formik.values.zip_code}
                      // onChange={formik.handleChange}
                      onChange={(e) => handleInputChange('zip_code', e)}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.zip_code && formik.touched.zip_code}
                    />
                    {formik.errors.zip_code && formik.touched.zip_code && (
                      <p style={{ color: "red" }}> {formik.errors.zip_code}</p>
                    )}
                  </div>
                </Col>
                {/* <Col md={6}>
                  <div>
                    <Label for="city">
                      City Name
                      <Required />
                    </Label>
                    <Input
                      id="city"
                      placeholder="City name"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.city && formik.touched.city}
                    />
                    {formik.errors.city && formik.touched.city && <p style={{ color: "red" }}> {formik.errors.city}</p>}
                  </div>
                </Col> */}
              </Row>

              <Row className="mt-2 g-2">
                <Col md={4}>
                  <div>
                    <Label for="country">
                      Select Country
                      {/* <Required /> */}
                    </Label>
                    <ReactSelect
                      disabled={isViewOnly}
                      options={countriesOptions}
                      id="country"
                      name="country"
                      api="/post"
                      value={formik.values.country}
                      onChange={(e) => handelCountryDropdown(e)}
                      onBlur={() => formik.setFieldTouched("country", true)}
                      invalid={formik.errors.country && formik.touched.country}
                    />
                    {/* {formik.errors.country && formik.touched.country && (
                      <p style={{ color: "red" }}> {formik.errors.country}</p>
                    )} */}
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Label for="state">
                      Select State
                      {/* <Required /> */}
                    </Label>
                    <ReactSelect
                      disabled={isViewOnly}
                      options={statesOptions}
                      id="state"
                      name="state"
                      value={formik.values.state}
                      onChange={(e) => handelStateDropdown(e)}
                      onBlur={() => formik.setFieldTouched("state", true)}
                      invalid={formik.errors.state && formik.touched.state}
                    />
                    {/* {formik.errors.state && formik.touched.state && (
                      <p style={{ color: "red" }}> {formik.errors.state}</p>
                    )} */}
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Label for="city">
                      Select City
                      {/* <Required /> */}
                    </Label>
                    <ReactSelect
                      disabled={isViewOnly}
                      options={citiesOptions}
                      id="city"
                      name="city"
                      value={formik.values.city}
                      onChange={(e) => handelCityDropdown(e)}
                      onBlur={() => formik.setFieldTouched("city", true)}
                      // invalid={formik.errors.state && formik.touched.state}
                    />
                    {/* {formik.errors.city && formik.touched.city && (
                        <p style={{ color: "red" }}>{formik.errors.city}</p>
                      )} */}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                  <Col md={12}>
                    <div>
                      <Label for="notes">Notes</Label>
                      <textarea
                        readOnly={isViewOnly}
                        as="textarea"
                        id="notes"
                        maxLength={255}
                        placeholder="Enter notes here"
                        rows={3}
                        className="form-control"
                        name="notes"
                        value={formik.values.notes}
                        // onChange={formik.handleChange}
                        onChange={(e) => handleInputChange("notes", e)}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                </Row>
            </Col>

            {/* Second Section */}
            <Col md={6} className="border p-3 bg-white border-start-0">
              <Row className=" g-2">
                <Col md={4}>
                  <div>
                    <Label for="email">
                      Email Address <Required />
                    </Label>
                    <Input
                      readOnly={isViewOnly}
                      id="email_address"
                      type="email"
                      placeholder="Enter email"
                      name="email_address"
                      value={formik.values.email_address}
                      // onChange={formik.handleChange}
                      onChange={(e) => handleInputChange('email_address', e)}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.email_address && formik.touched.email_address}
                      autoComplete="off"
                    />
                    {formik.errors.email_address && formik.touched.email_address && (
                      <p style={{ color: "red" }}> {formik.errors.email_address}</p>
                    )}
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Label for="mobileno">
                      Mobile No 
                      {/* <Required /> */}
                    </Label>
                    <Input
                      readOnly={isViewOnly}
                      id="phone_number"
                      type="number"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      placeholder="Enter mobile no"
                      name="phone_number"
                      value={formik.values.phone_number}
                      // onChange={formik.handleChange}
                      onChange={(e) => handleInputChange('phone_number', e)}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.phone_number && formik.touched.phone_number}
                      autoComplete="off"
                    />
                    {formik.errors.phone_number && formik.touched.phone_number && (
                      <p style={{ color: "red" }}> {formik.errors.phone_number}</p>
                    )}
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Label for="teli"> Office Telephone</Label>
                    <Input
                      readOnly={isViewOnly}
                      id="office_telephone"
                      type="number"
                      placeholder="Enter office telephone"
                      name="office_telephone"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      value={formik.values.office_telephone}
                      // onChange={formik.handleChange}
                      onChange={(e) => handleInputChange('office_telephone', e)}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.office_telephone && formik.touched.office_telephone}
                      autoComplete="off"
                    />
                    {formik.errors.office_telephone && formik.touched.office_telephone && (
                      <p style={{ color: "red" }}> {formik.errors.office_telephone}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="mt-2">
                <Label for="room"> Distance</Label>
                <div className="input-group">
                  
                <Input
                  readOnly={isViewOnly}
                  className="noscroll"
                  type="number"
                  id="distance"
                  placeholder="Enter Distance to Venue"
                  name="distance"
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  value={formik.values.distance}
                  // onChange={formik.handleChange}
                  onChange={(e) => handleInputChange('distance', e)}
                  onBlur={formik.handleBlur}
                  invalid={formik.errors.distance && formik.touched.distance}
                  autoComplete="off"
                />
                {formik.errors.distance && formik.touched.distance && (
                  <p style={{ color: "red" }}> {formik.errors.distance}</p>
                )}
                <div className="input-group-append">
                    <span className="input-group-text">miles</span>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <Label for="room"> Travel cost</Label>
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text">$</span>
                  </div>
                  <Input
                    readOnly={isViewOnly}
                    id="travel_cost"
                    type="number"
                    className="form-control noscroll"
                    name="travel_cost"
                    placeholder="$ 0"
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    value={formik.values.travel_cost}
                    // onChange={formik.handleChange}
                    onChange={(e) => handleInputChange('travel_cost', e)}
                    invalid={formik.errors.travel_cost && formik.touched.travel_cost}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Col>
          </Row>
          {isViewOnly ? (
            ""
          ) : (
            <NavigationFooter
              lastPage={"1"}
              currentState={"1"}
              formik={formik}
              disableSave={verifyAllFields}
              isVenueadding={isVenueadding}
              isVenueEditing={isVenueEditing}
              validationFunction={handelValidation}
            />
          )}
        </section>
      )}
    </>
  );
};
export default CreateVenuePage;
