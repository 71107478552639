import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ReactDataTable } from "../../components/Component";
import { getPackageList } from "../../http/get/getApi";
import NavigationHeader from "../components/NavigationHeader";
import TableActionBar from "../components/TableActionBar";
import { deletePackage } from "../../http/delete/deleteApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";

const PackageListPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");

  //get package list
  const {
    data: packageList,
    isLoading: packagListLoading,
    isError: packageListIsError,
  } = useQuery({
    queryKey: ["get-packages-list", pageNo, recordLimit, search],
    queryFn: () => getPackageList({ id: "", page: pageNo, limit: recordLimit, search: search }),
    staleTime: 500,
    cacheTime: 30000, 
    retry: 1,
    // onSuccess: () => {
    //   queryClient.invalidateQueries(["get-packages-list"]);
    // },
  });

  // useMutation to delete package
  const {
    mutate: deletePackageMutation,
    isLoading: isPackageDeleting,
    error: deleteError,
  } = useMutation({
    mutationKey: ["delete-package-by-Id"],
    mutationFn: (ID) => deletePackage({ package_id: ID }),
    onSuccess: () => {
      queryClient.invalidateQueries(["get-packages-list"]);
    },
  });
  const deleteRecord = async (value) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deletePackageMutation(value);
          packageList.data = packageList?.data?.packages?.filter((pkg) => pkg?.id !== value);
          Swal.fire({
            title: "Deleted!",
            text: "Your package has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        queryClient.invalidateQueries(["get-packages-list"]);
      });
    } catch (error) {
      console.error("Error deleting package:", error);
    }
  };

  const isLoading = isPackageDeleting || packagListLoading;

  const tableHeaddings = [
    {
      name: "Sr. No.",
      // selector: (row, index) => index + 1,
      selector: (row, index) => (pageNo * recordLimit) + index + 1,
      maxWidth: "5px",
    },
    {
      name: "Package Name",
      selector: (row) => row.package_name,
    },
    {
      name: "Package Price",
      selector: (row) => `$${row.package_price}`,
    },
    {
      name: "Deposit Price",
      selector: (row) => row.package_deposite_price ? `$${row.package_deposite_price}` : "----",
    },
    {
      name: "Overtime Price",
      selector: (row) => row.package_overtime ? `$${row.package_overtime}` : "----",
    },
    {
      name: "Actions",
      allowOverflow: true,
      cell: (row) => (
        <>
          <TableActionBar
          options1Icon="eye"
            options1TooleTipText="View"
            options2Icon="icon ni ni-edit"
            options2TooleTipText="Edit"
            options3Icon="icon ni ni-trash"
            options3TooleTipText="Delete"
            // options1onClick={() => navigate(`/package-list/create-package?type=view&id=${row.id}&from=fromView`)}
            options1onClick={() => navigate(`/package-list/create-package/preview?id=${row.id}&from=previewdetail`)}
            options2onClick={() => navigate(`/package-list/create-package?type=edit&id=${row.id}&from=fromEdit`)}
            options3onClick={() => deleteRecord(row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <NavigationHeader
        heading={"Package List"}
        buttonInnerText={"Add Package"}
        route={"/package-list/create-package"}
      />
      <section className="m-4 border p-3 bg-white">
        <ReactDataTable
          loading={isLoading}
          data={packageList?.data?.packages || []}
          columns={tableHeaddings}
          pagination
          actions
          searchBy="package_name"
          pageNo={setPageNo} // sending function to change page no
          activePage={packageList?.data?.page || 0} // sending the current page no
          totalRecords={packageList?.data?.totalRecords || 10} // sending the total no of records om database
          recordLimit={setRecordLimit} //setting up value of no of records to display
          setSearch={setSearch} // send search value setFunction
        />
      </section>
    </>
  );
};
export default PackageListPage;
