import React, { useState } from "react";
import { Button, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import uploadImage from "../../assets/images/uploadImage.png";
import Swal from "sweetalert2";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../src/pages/components/CroppedImg"; // Helper function to get cropped image
import heic2any from "heic2any";

const UploadImage = ({
  label = "Upload Image",
  name,
  formik,
  disabled = false,
  setHasUnsavedChanges,
  isViewOnly,
}) => {
  const [fileSelected, setFileSelected] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const sizeLimit = 10 * 1024 * 1024; // 10 MB size limit

  // const handleFileChange = (event) => {
  //   const file = event?.target?.files[0];
  //   console.log("file",file)

  //   if (file) {
  //     if (file.size > sizeLimit) {
  //       Swal.fire({
  //         icon: "warning",
  //         text: "Please add an image below 10 MB.",
  //         showConfirmButton: false,
  //         timer: 3000,
  //       });
  //       return;
  //     }

  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImageSrc(reader.result); // Base64 image to be used for cropping
  //       setCropModalOpen(true); // Open the crop modal
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };


  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    const sizeLimit = 10 * 1024 * 1024; // 10 MB limit
  
    if (file) {
      if (file.size > sizeLimit) {
        Swal.fire({
          icon: "warning",
          text: "Please add an image below 10 MB.",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }
  
      // Check if the file is HEIC/HEIF based on either the file type or extension
      const isHeicFile = file.type === "image/heic" || file.type === "image/heif" || file.name.toLowerCase().endsWith(".heic") || file.name.toLowerCase().endsWith(".heif");
  
      if (isHeicFile) {
        try {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg", // Convert to JPEG
          });
  
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageSrc(reader.result); // Set base64 image for preview/cropping
            setCropModalOpen(true); // Open crop modal
          };
          reader.readAsDataURL(convertedBlob); // Convert the blob to base64
  
        } catch (error) {
          console.error("Error converting HEIC/HEIF file:", error);
          Swal.fire({
            icon: "error",
            text: "Failed to process HEIC/HEIF image.",
            showConfirmButton: true,
          });
        }
      } else {
        // For non-HEIC images (e.g., JPEG/PNG)
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc(reader.result); // Set base64 image for preview/cropping
          setCropModalOpen(true); // Open crop modal
        };
        reader.readAsDataURL(file); // Convert the file to base64
      }
    }
  };
  

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleCropConfirm = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedArea);
      formik.setFieldValue(name, croppedImage); // Set cropped image in formik
      setFileSelected(true);
      setCropModalOpen(false); // Close crop modal
    } catch (error) {
      console.error("Failed to crop image", error);
    }
  };

  const handleRemoveClick = () => {
    formik.setFieldValue(name, "");
    formik.setFieldValue("imagePresent", 0);
    setFileSelected(false);
  };

  return (
    <>
      <Label for="upload">{label}</Label>
      <div className="d-flex flex-column align-items-center">
        <div
          onClick={() => document.getElementById("upload").click()}
          style={{ cursor: "pointer" }}
        >
          <img
            src={formik.values[name] ? formik.values[name] : uploadImage}
            alt="Preview"
            style={{ width: "100%", height: "170px", objectFit: "contain" }}
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop in case fallback also fails
              e.target.src = uploadImage; // Set fallback image if the URL is broken
            }}
            className="mb-2"
          />
        </div>

        {!isViewOnly &&
          (formik.values[name] ? (
            <div className="d-flex justify-content-between">
              <Button color="danger" type="button" className="btn-dim" disabled={disabled} onClick={handleRemoveClick}>
                <em className="icon ni ni-trash"></em>
                <span>Remove</span>
              </Button>
              <Label for="upload" className="border mb-0 ms-2 rounded p-2 custom-file-upload">
                {fileSelected ? "Upload a new file" : "Choose a file"}
              </Label>
              <Input
                type="file"
                id="upload"
                accept="image/*"
                name={name}
                onChange={handleFileChange}
                disabled={disabled}
                onBlur={formik.handleBlur}
                style={{ display: "none" }}
              />
            </div>
          ) : (
            <>
              <Label for="upload" className="border w-80 text-center rounded p-2 custom-file-upload">
                {fileSelected ? "Upload a new file" : "Choose a file"}
              </Label>
              <Input
                type="file"
                id="upload"
                accept="image/*"
                name={name}
                onChange={handleFileChange}
                disabled={disabled}
                onBlur={formik.handleBlur}
                style={{ display: "none" }}
              />
            </>
          ))}

        {/* Crop Modal */}
        <Modal isOpen={cropModalOpen} toggle={() => setCropModalOpen(!cropModalOpen)}>
          <ModalBody>
            <div style={{ position: "relative", width: "100%", height: 300 }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleCropConfirm}>
              Crop & Save
            </Button>
            <Button color="secondary" onClick={() => setCropModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default UploadImage;
