import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { useThemeUpdate } from "../../../provider/Theme";

const AppDropdown = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };
  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <div className="icon-status icon-status-na">
          <Icon name="menu-circled"></Icon>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg">
        <div className="dropdown-body">
          <ul className="list-apps">
            {/* Dashboard */}
            <li>
              <Link to={`${process.env.PUBLIC_URL}/`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="dashboard" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Dashboard</span>
              </Link>
            </li>
            {/* Add event */}
            <li>
              <Link to={`${process.env.PUBLIC_URL}/add-event`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="calendar-alt" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Add event</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/event-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="icon ni ni-list-thumb-alt" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Event list</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/client-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="user-c" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Clients</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/employee-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="icon ni ni-users" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Employee</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/venue-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="icon ni ni-location" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Venue</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/vendor-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="icon ni ni-home-alt" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Vendor</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/package-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="package" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Package</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/Company-profile`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="user-circle" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Profile</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/inquiry-list`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="user-list" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Inquiry List</span>
              </Link>
            </li>
            <li>
              <Link to={`${process.env.PUBLIC_URL}/plan-selection`} onClick={toggle}>
                <span className="list-apps-media">
                  <Icon name="contact" className="bg-primary text-white"></Icon>
                </span>
                <span className="list-apps-title">Select Plan</span>
              </Link>
            </li>
          </ul>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AppDropdown;
