import React, { useContext, useState } from "react";
import NavigationFooter from "../components/NavigationFooter";
import { Col, Input, Row } from "reactstrap";
import ReactSelect from "react-select";
import Required from "../components/Required";
import { useQuery } from "react-query";
import { getCategories, getGenres, getServices } from "../../http/get/getApi";
import { transformData } from "../../utils/Utils";
import { DataContext } from "../../App";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import subscribeimg from "../../../src/assets/images/subscribe.jpg"
import { useTheme } from "../../layout/provider/Theme";

const Services = ({
  currentState,
  formik,
  toggle,
  isCompanyProfileEditing,
  mixersLimit,
  serviceList,
  serviceCount,
  subscribedpalresponce,
  styles
}) => {
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [isGenreMenuOpen, setIsGenreMenuOpen] = useState(false);
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  // const [mixerLimitmessage, setmixerLimitmessage] = useState("");

  const { skin } = useTheme();

  const customStyles = {
    // Style the container
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fff" : "#fff",  // Example dark mode background
      color: "black", // Change text color
      borderColor: state.isFocused ? "#60CF4F" : "#444",  // Change border color when focused
      boxShadow: state.isFocused ? "0 0 0 1px #60CF4F" : null,  // Highlight border on focus
    }),
    // Style for each option in the dropdown
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#60CF4F" // Change background color when option is selected
        : state.isFocused
        ? "#60CF4F" // Change background color when option is hovered
        : null,
      color: state.isSelected ? "#ffff" : "#000000", // Change text color for selected and hovered options
      padding: 10,
    }),
    // Style for the dropdown menu
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff", // Background of dropdown
    }),
  };

  const darkModeStyles = {
    control: (provided, _state) => ({
      ...provided,
      backgroundColor: "#141c26",
      color: "white",
      // border: "1px solid #384d69",
      borderColor: _state.isFocused ? "#60CF4F" : "#60CF4F",
      boxShadow: _state.isFocused ? "0 0 0 1px #60CF4F" : null,
    }),
    placeholder: (provided, _state) => ({ ...provided, color: "#fff", opacity: 0.7 }),
    singleValue: (provided, _state) => ({ ...provided, color: "white" }),
    valueContainer: (provided, _state) => ({ ...provided, color: "white" }),
    option: (provided, _state) => ({
      ...provided,
      // backgroundColor: "#141c26",
      backgroundColor: _state.isSelected
        ? "#60CF4F" // Change background color when option is selected
        : _state.isFocused
        ? "#60CF4F" // Change background color when option is hovered
        : null,
      color: _state.isSelected ? "#ffff" :  _state.isFocused ? "#000000" : "#fff", 
      // color: "white",
      border: "1px solid #384d69",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#141c26", // Background of dropdown
    }),
  };
  const { hasUnsavedChanges, setHasUnsavedChanges,  activeTab, setActivetab} = useContext(DataContext);

  const [currentServiceCount, setCurrentServiceCount] = useState(0);
  const [serviceMessage, setserviceMessage] = useState("");
  const [selectedServiceIdslength, setselectedServiceIdslength] = useState(0);
  
  const navigate = useNavigate()


  const handleCategories = (selectedOptions) => {
    
    const selectedCategoryIds = selectedOptions.map((option) => Number(option.value));
    formik.setFieldValue("category", selectedCategoryIds);
    setHasUnsavedChanges(true);
  };

  const handleGenreChange = (selectedOptions) => {
    const selectedGenreIds = selectedOptions.map((option) => option.value);
    formik.setFieldValue('genres', selectedGenreIds);
    setHasUnsavedChanges(true);
  };

  const handleServices = (selectedOptions) => {
    const selectedServiceIds = selectedOptions.map((option) => option.value);
    const currentCount = selectedServiceIds.length;
  
    // If subscribed response is false
    if (!subscribedpalresponce) {
      // setserviceMessage("Please subscribe to a plan");
      Swal.fire({
        title: "Subscription Required",
        text: "Subscribe to one of our plans and enjoy the ability to add more services to your account.",
        imageUrl: subscribeimg,
        imageWidth: 250,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCancelButton: true,
        confirmButtonText: "Subscribe Now",
        cancelButtonText: "Maybe Later",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/plan-selection')
          setActivetab("3")
        }
      });
      
      return; // Exit the function without updating the state
    }else {

    // Check if the current selection exceeds the service limit
    if (currentCount > serviceList) {
      setserviceMessage(`Your Plan Limit is for Services ${serviceList}. If you want to add more services, upgrade your plan.`);
      Swal.fire({
        title: "Service Limit Exceeded!",
        text: `You've reached the maximum of ${serviceList} services. Upgrade your plan to add more and unlock additional features.`,
        icon: "warning",
        confirmButtonText: "Upgrade Now",
        cancelButtonText: "Maybe Later",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/plan-selection')
        }
      });
    }else{

        // If the selection is within limits and the user is subscribed
    formik.setFieldValue('services', selectedServiceIds);
    setCurrentServiceCount(currentCount);
    setserviceMessage('');
    setHasUnsavedChanges(true);
    setselectedServiceIdslength(currentCount);
    }
  
  }
};
  
  


  const handelValidation = (callbackFun) => {
    // callbackFun(true);
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== "" 
    ) {
      callbackFun(true);
    }else{
      callbackFun(false);
    }
  };

  // categories ----------------------------------------------------------------------

  //fetching Categories data
  const {
    data: Categories,
    isLoading: categoriesLoading,
    isError: categoriesError,
  } = useQuery({
    queryKey: ["get-categories"],
    queryFn: () => getCategories(),
    staleTime: 20000,
    cacheTime: 300000,
    retry: 1,
  });

  //optiopns for Categories dropdown
  const categories = transformData({
    dataArray: Categories?.data?.Category || [],
    label: "title",
    value: "id",
    isLoading: categoriesLoading,
  });

  const selectedCategoryLabels = formik.values?.category?.map((category) =>
    typeof category === 'string'
      ? categories.find((cat) => cat.label === category)
      : categories.find((cat) => cat.value === category)
  ).filter(Boolean);

  const availableOptions = categories?.filter((option) => {
    const category = formik.values?.category;
  
    // Check if category is defined and has at least one element
    if (category && category.length > 0) {
      return typeof category[0] === 'string'
        ? !category.includes(option.label)
        : !category.includes(option.value);
    }
  
    // If category is undefined or empty, return true for all options
    return true;
  });
  



  // Serivces-----------------------------------------------------------------------------------

  //fetching Serivces data
  const {
    data: servicesData,
    isLoading: servicesLoading,
    isError: servicesError,
  } = useQuery({
    queryKey: ["get-services"],
    queryFn: () => getServices(),
    staleTime: 20000,
    cacheTime: 300000,
    retry: 1,
  });


  //optiopns for Serivces dropdown
  const servicesOptions = transformData({
    dataArray: servicesData?.data?.Services || [],
    label: "title",
    value: "id",
    isLoading: servicesLoading,
  });

  const selectedServices = formik.values?.services?.map((service) =>
    typeof service === 'string'
      ? servicesOptions.find((opt) => opt.label === service)
      : servicesOptions.find((opt) => opt.value === service)
  ).filter(Boolean);

  const availableServiceOptions = servicesOptions.filter((option) => {
    const services = formik.values?.services;
  
    // Check if services is defined and has at least one element
    if (services && services.length > 0) {
      return typeof services[0] === 'string'
        ? !services.includes(option.label)
        : !services.includes(option.value);
    }
  
    // If services is undefined or empty, return true for all options
    return true;
  });
  


  // Genre -------------------------------------------------------------

  //fetching genreOptions data
  const {
    data: genresData,
    isLoading: genresLoading,
    isError: genresError,
  } = useQuery({
    queryKey: ["get-genres"],
    queryFn: () => getGenres(),
    staleTime: 20000,
    cacheTime: 300000,
    retry: 1,
  });

  //optiopns for genresData dropdown
  const genreOptions = transformData({
    dataArray: genresData?.data?.genres || [],
    label: "title",
    value: "id",
    isLoading: genresLoading,
  });


  const selectedGenres = formik.values?.genres?.map((genre) =>
    typeof genre === 'string'
      ? genreOptions.find((opt) => opt.label === genre)
      : genreOptions.find((opt) => opt.value === genre)
  ).filter(Boolean);

  const availableGenreOptions = genreOptions.filter((option) => {
    const genres = formik.values?.genres;
  
    // Check if genres is defined and has at least one element
    if (genres && genres.length > 0) {
      return typeof genres[0] === 'string'
        ? !genres.includes(option.label)
        : !genres.includes(option.value);
    }
  
    // If genres is undefined or empty, return true for all options
    return true;
  });
  


  return (
    <Row className="p-2 py-0">
      {/* First Section  */}
      <Col md={6} className="border p-3 bg-white">
        <section>
          <Row>
            <Col md={12}>
              <label htmlFor="categories">Categories</label>
              <ReactSelect
                id="categories"
                name="categories"
                options={availableOptions}
                isMulti
                value={selectedCategoryLabels}
                onChange={handleCategories}
                menuIsOpen={isCategoryMenuOpen}
                onFocus={() => setIsCategoryMenuOpen(true)}
                onBlur={() => setIsCategoryMenuOpen(false)}
                styles={skin === "dark" ? { ...darkModeStyles, ...styles } : customStyles}
              />
            </Col>
          </Row>
        </section>

        {/* Genres */}
        <section className="mt-3">
          <Row>
            <Col md={12}>
              <label htmlFor="genres">Genres</label>
              <ReactSelect
                id="genres"
                name="genres"
                options={availableGenreOptions}
                isMulti
                value={selectedGenres}
                onChange={handleGenreChange}
                menuIsOpen={isGenreMenuOpen}
                onFocus={() => setIsGenreMenuOpen(true)}
                onBlur={() => setIsGenreMenuOpen(false)}
                styles={skin === "dark" ? { ...darkModeStyles, ...styles } : customStyles}
              />
            </Col>
          </Row>
        </section>
      </Col>

      {/* Services  */}
      <Col md={6} className="border p-3 bg-white">
        <section>
          <Row>
            <Col md={12}>
              <label htmlFor="services">Services</label>
              <ReactSelect
                id="services"
                name="services"
                options={availableServiceOptions}
                isMulti
                value={selectedServices}
                onChange={handleServices}
                menuIsOpen={isServicesMenuOpen}
                onFocus={() => setIsServicesMenuOpen(true)}
                onBlur={() => setIsServicesMenuOpen(false)}
                styles={skin === "dark" ? { ...darkModeStyles, ...styles } : customStyles}
              />
            </Col>
            {/* {!(selectedServiceIdslength <= serviceList) ? <span style={{ color: "red" }}>{serviceMessage}</span> : ""} */}
          </Row>
        </section>
      </Col>

      {/* Navigation and Footer  */}
      <NavigationFooter
        toggle={toggle}
        currentState={currentState}
        lastPage="8"
        validationFunction={handelValidation}
        formik={formik}
        isCompanyProfileEditing={isCompanyProfileEditing}
      />
    </Row>
  );
};

export default Services;
