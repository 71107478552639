import React, { useContext, useState } from "react";
import NavigationFooter from "../components/NavigationFooter";
import { Col, Input, Label, Row } from "reactstrap";
// import ReactSelect from "react-select";
import ReactSelect from "../../../src/components/ReactSelect/ReactSelect";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { getCities, getCountries, getStates } from "../../http/get/getApi";
import { transformData } from "../../utils/Utils";
import UploadImage from "../components/UploadImage";
import Required from "../components/Required";
import { toast } from "react-toastify";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { DataContext } from "../../App";

const BasicInfo = ({ formik, currentState, toggle, isCompanyProfileEditing, companyprofileLoading }) => {

  const { hasUnsavedChanges, setHasUnsavedChanges} = useContext(DataContext);
  const [cityName, SetCityNmae] = useState("")

  const handelCountryDropdown = (e) => {
    formik.setFieldValue("country", e.value);
    setHasUnsavedChanges(true);
  };
  const handelStateDropdown = (e) => {
    formik.setFieldValue("state", e.value);
    setHasUnsavedChanges(true);
  };

  const handelCityDropdown = (e) => {
    formik.setFieldValue("city", e.value);
    SetCityNmae(e.label)
    setHasUnsavedChanges(true);
  };

  // const getCoordinates = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
  //         cityName
  //       )}&format=json`
  //     );
  
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  
  //     const data = await response.json();
  
  //     if (data.length > 0) {
  //       const location = data[0];
  //       formik.setFieldValue('latitude', location.lat);
  //       formik.setFieldValue('longitude', location.lon);
  //       alert(null);
  //     } else {
  //       alert('Unable to geocode the address. Please try again.');
  //     }
  //   } catch (error) {
  //     alert('Error occurred while fetching location data.');
  //   }
  // };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
        formik.setFieldValue("latitude", position.coords.latitude);
        formik.setFieldValue("longitude", position.coords.longitude);

        },
        () => {
          alert("Geolocation is not supported by this browser.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  
  

  //fetching countries data
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-country"],
    queryFn: () => getCountries(),
    staleTime: 60000,
    cacheTime: 300000,
    retry: 1,
  });

  //optiopns for countries dropdown
  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  let countryId = formik.values.country;

  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", countryId],
    queryFn: () => getStates({ id: countryId }),
    staleTime: 60000,
    cacheTime: 300000,
    retry: 1,
  });
  

  //optiopns for states dropdown
  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });


  // ---------------------
  let stateId = formik.values.state;

  const {
    data: cities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useQuery({
    queryKey: ["get-states-by-country", stateId],
    queryFn: () => getCities({ id: stateId }),
    staleTime: 60000,
    cacheTime: 300000,
    retry: 1,
  });

  //optiopns for cities dropdown
  const citiesOptions = transformData({
    dataArray: cities?.data?.cities || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  
  // ------------


  const handelValidation = (callbackFun) => {
    if (formik.values.first_name === "") {
      formik.setFieldTouched("first_name", true);
    }

    if (formik.values.last_name === "") {
      formik.setFieldTouched("last_name", true);
    }

    if (formik.values.phone_number === "") {
      formik.setFieldTouched("phone_number", true);
    }
    // if (formik.values.zip_code === "") {
    //   formik.setFieldTouched("zip_code", true);
    // }

    // if (formik.values.email === "") {
    //   formik.setFieldTouched("email", true);
    // }

    if (formik.values.city === "") {
      formik.setFieldTouched("city", true);
    }
    if (formik.values.country === "") {
      formik.setFieldTouched("country", true);
    }
    if (formik.values.state === "") {
      formik.setFieldTouched("state", true);
    }
    // if (formik.values.files === "") {
    //   formik.setFieldTouched("files", true);
    // }
    // if (formik.values.uniqueness === "") {
    //   formik.setFieldTouched("uniqueness", true);
    // }
    // if (formik.values.contact_number === "") {
    //   formik.setFieldTouched("contact_number", true);
    // }

    // if (formik.errors.email && formik.values.email !== "") {
    //   toast.warning("Invalid Email.");
    //   return;
    // }

    // if (formik.errors.zip_code && formik.values.zip_code !== "") {
    //   toast.warning("Invalid zip-code.");
    //   return;
    // }

    if (
      formik.values.first_name !== "" &&
      formik.values.last_name !== "" &&
      formik.values.phone_number !== "" &&
      formik.values.country !== "" &&
      formik.values.state !== "" &&
      formik.values.city !== "" 
    ) {
      callbackFun(true);
    }
  };

  // const handelValidation = (callbackFun) => {
  //   callbackFun(true);
  // };

  const handleName = (e) => {
    const { value } = e.target;
    let wordCount = 0;
  
    // Check if the input is not empty
    if (value.trim() !== '') {
      wordCount = value.trim().split(/\s+/).length;
    }
  
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('nameCount', wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleLastName = (e) => {
    const { value } = e.target;
    let wordCount = 0;
  
    // Check if the input is not empty
    if (value.trim() !== '') {
      wordCount = value.trim().split(/\s+/).length;
    }
  
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('lastNameCount', wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleContactNumber = (e) => {
    const { value } = e.target;
    let wordCount = 0;
  
    // Check if the input is not empty
    if (value.trim() !== '') {
      wordCount = value.trim().split(/\s+/).length;
    }
  
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('contactNumberCount', wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handlePersonalContactNumber = (e) => {
    const { value } = e.target;
    let wordCount = 0;
  
    // Check if the input is not empty
    if (value.trim() !== '') {
      wordCount = value.trim().split(/\s+/).length;
    }
  
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('personal_phone_number_count', wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleAddress1 = (e) => {
    const { value } = e.target;
    let wordCount = 0;
  
    // Check if the input is not empty
    if (value.trim() !== '') {
      wordCount = value.trim().split(/\s+/).length;
    }
  
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('address1Count', wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleAddress2 = (e) => {
    const { value } = e.target;
    let wordCount = 0;
  
    // Check if the input is not empty
    if (value.trim() !== '') {
      wordCount = value.trim().split(/\s+/).length;
    }
  
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('address2Count', wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleZipCode = (e) => {
    const { value } = e.target;
    let wordCount = 0;
  
    // Check if the input is not empty
    // if (value.trim() !== '') {
    //   wordCount = value?.trim()?.split(/\s+/)?.length;
    // }
  
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('zipCodeCount', wordCount);
    setHasUnsavedChanges(true);
    formik.handleChange(e);
  };

  const handleLatitude = (e) => {
    // const { value } = e.target;
    const value = parseFloat(e.target.value);
    // let wordCount = 0;
    
    // Check if the input is not empty
    // if (value.trim() !== '') {
    //   wordCount = value?.trim()?.split(/\s+/)?.length;
    // }
    
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('latitude', value);
    // console.log("value",value)
    setHasUnsavedChanges(true);
    // formik.handleChange(value);
  };

  const handleLongitude = (e) => {
    // const { value } = e.target;
    const value = parseFloat(e.target.value);
    // let wordCount = 0;
  
    // Check if the input is not empty
    // if (value.trim() !== '') {
    //   wordCount = value.trim().split(/\s+/).length;
    // }
  
    // console.log("wordCountUU", wordCount);
    formik.setFieldValue('longitude', value);
    setHasUnsavedChanges(true);
    // formik.handleChange(value);
  };

  return (
    <div>
      {companyprofileLoading ? (
        <div>
          <Row>
            <Col md={6} className="border p-3 bg-white">
              <Row>
                <Col md={6} className=" p-3 bg-white">
                  <ShimmerThumbnail height={250} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                  <ShimmerThumbnail height={40} width={300} rounded />
                </Col>
                <Col md={6} className=" p-3 bg-white">
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                  <ShimmerThumbnail height={50} width={300} rounded />
                </Col>
              </Row>
            </Col>
            <Col md={6} className=" border p-3 bg-white">
              <Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                  <Col md={4}>
                    <ShimmerThumbnail height={40} width={200} rounded />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <Row className="p-2 py-0">
            {/* First Section */}
            <Col md={6} className="border p-3 bg-white">
              <section>
                <Row className="g-2">
                  <Col md={6}>
                    <UploadImage label="Upload profile picture" name="files" formik={formik} setHasUnsavedChanges={setHasUnsavedChanges} />
                    {formik.errors.files && formik.touched.files && (
                      <p style={{ color: "red" }}>{formik.errors.files}</p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div>
                      <Label for="username">
                        Username
                        {/* <Required /> */}
                      </Label>
                      <Input
                        disabled={true}
                        type="text"
                        id="username"
                        name="username"
                        value={formik.values.username}
                        placeholder="Enter username"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.username && formik.touched.username}
                      />
                      {formik.errors.username && formik.touched.username && (
                        <p style={{ color: "red" }}>{formik.errors.username}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <Label for="first_name">
                        First Name
                        <Required />
                      </Label>
                      <Input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formik.values.first_name}
                        placeholder="Enter first name"
                        // onChange={formik.handleChange}
                        onChange={handleName}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.first_name && formik.touched.first_name}
                      />
                      {formik.errors.first_name && formik.touched.first_name && (
                        <p style={{ color: "red" }}>{formik.errors.first_name}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <Label htmlFor="last_name">
                        Last Name
                        <Required />
                      </Label>
                      <Input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formik.values.last_name}
                        placeholder="Enter last name"
                        // onChange={formik.handleChange}
                        onChange={handleLastName}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.last_name && formik.touched.last_name}
                      />
                      {formik.errors.last_name && formik.touched.last_name && (
                        <p style={{ color: "red" }}>{formik.errors.last_name}</p>
                      )}
                    </div>
                    
                  </Col>
                </Row>
                <Row>
                <Col md={6}>
                <div className="mt-4">
                      <Label for="phone_number">
                      Office Mobile Number 
                        <Required />
                      </Label>
                      <Input
                        type="number"
                        id="phone_number"
                        name="phone_number"
                        value={formik.values.phone_number}
                        placeholder="Enter Office Mobile Number"
                        // onChange={formik.handleChange}
                        onChange={handleContactNumber}
                        onBlur={formik.handleBlur}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        invalid={formik.errors.phone_number && formik.touched.phone_number}
                      />
                      {formik.errors.phone_number && formik.touched.phone_number && (
                        <p style={{ color: "red" }}>{formik.errors.phone_number}</p>
                      )}
                    </div>
                    </Col>
                    <Col md={6}>
                    <div className="mt-4">
                      <Label for="personal_phone_number">
                      Personal Mobile Number
                      </Label>
                      <Input
                        type="number"
                        id="personal_phone_number"
                        name="personal_phone_number"
                        value={formik.values.personal_phone_number}
                        placeholder="Enter Personal Mobile Number"
                        // onChange={formik.handleChange}
                        onChange={handlePersonalContactNumber}
                        onBlur={formik.handleBlur}
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        // invalid={formik.errors.phone_number && formik.touched.phone_number}
                      />
                    </div>
                    </Col>
                </Row>
              </section>
            </Col>
            {/* Second Section */}
            <Col md={6} className="border p-3 bg-white border-start-0">
              <section>
                {/* First Row */}
                <Row className="g-2">
                  <Col md={4}>
                    <div>
                      <Label for="email">
                        Email
                        {/* <Required /> */}
                      </Label>
                      <Input
                        disabled={true}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.email && formik.touched.email}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p style={{ color: "red" }}>{formik.errors.email}</p>
                      )}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      {/* <Label for="address_1">Address Line 1</Label> */}
                      <Label for="address_1">Business Address 1</Label>
                      <Input
                        id="address_1"
                        placeholder="Enter Address 1"
                        maxLength={30}
                        name="address_1"
                        value={formik.values.address_1}
                        // onChange={formik.handleChange}
                        onChange={handleAddress1}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      {/* <Label for="address_2">Address Line 2</Label> */}
                      <Label for="address_2">Business Address 2</Label>
                      <Input
                        id="address_2"
                        placeholder="Enter Address 2"
                        maxLength={30}
                        name="address_2"
                        value={formik.values.address_2}
                        // onChange={formik.handleChange}
                        onChange={handleAddress2}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </Col>
                </Row>
                {/* Second Row */}
                <Row className="mt-2 g-2">
                  <Col md={4}>
                    <div>
                      <Label for="country">Select Country <Required /></Label>
                      <ReactSelect
                        options={countriesOptions}
                        id="country"
                        name="country"
                        value={formik.values.country}
                        onChange={(e) => handelCountryDropdown(e)}
                        onBlur={() => formik.setFieldTouched("country", true)}
                        invalid={formik.errors.country && formik.touched.country}
                      />
                      {formik.errors.country && formik.touched.country && (
                        <p style={{ color: "red" }}>{formik.errors.country}</p>
                      )}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="state">Select State <Required /></Label>
                      <ReactSelect
                        options={statesOptions}
                        id="state"
                        name="state"
                        value={formik.values.state}
                        onChange={(e) => handelStateDropdown(e)}
                        onBlur={() => formik.setFieldTouched("state", true)}
                        // invalid={formik.errors.state && formik.touched.state}
                      />
                      {formik.errors.state && formik.touched.state && (
                        <p style={{ color: "red" }}>{formik.errors.state}</p>
                      )}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="city">Select City <Required /></Label>
                      <ReactSelect
                        options={citiesOptions}
                        id="city"
                        name="city"
                        value={formik.values.city}
                        onChange={(e) => {handelCityDropdown(e); getLocation()}}
                        // onChange={(e) => handelCityDropdown(e)}
                        // onChange={(e) => {handelCityDropdown(e); getCoordinates()}}
                        onBlur={() => formik.setFieldTouched("city", true)}
                        invalid={formik.errors.city && formik.touched.city}
                      />
                      {formik.errors.city && formik.touched.city && (
                        <p style={{ color: "red" }}>{formik.errors.city}</p>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row className="mt-2 g-2">
                  <Col md={4}>
                    <div>
                      <Label for="zip_code">
                        Zip-code
                        {/* <Required /> */}
                      </Label>
                      <Input
                        id="zip_code"
                        type="number"
                        placeholder="Enter zip code"
                        name="zip_code"
                        value={formik.values.zip_code}
                        // onChange={formik.handleChange}
                        onChange={handleZipCode}
                        onBlur={formik.handleBlur}
                        invalid={formik.errors.zip_code && formik.touched.zip_code}
                      />
                      {formik.errors.zip_code && formik.touched.zip_code && (
                        <p style={{ color: "red" }}>{formik.errors.zip_code}</p>
                      )}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="latitude ">
                      latitude 
                      </Label>
                      <Input
                        id="latitude "
                        type="number"
                        placeholder="Enter latitude "
                        name="latitude "
                        value={formik.values.latitude }
                        // onChange={formik.handleChange}
                        onChange={handleLatitude}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div>
                      <Label for="longitude">
                      longitude
                      </Label>
                      <Input
                        id="longitude"
                        type="number"
                        placeholder="Enter longitude"
                        name="longitude"
                        value={formik.values.longitude}
                        // onChange={formik.handleChange}
                        onChange={handleLongitude}
                      />
                    </div>
                  </Col>
                </Row>
              </section>
            </Col>
            </Row>
            <Row className="g-2">

            <NavigationFooter
              toggle={toggle}
              currentState={currentState}
              lastPage="8"
              // validationFunction={handelValidation}
              validationFunction={handelValidation}
              formik={formik}
              isCompanyProfileEditing={isCompanyProfileEditing}
              />
      </Row>
        </>
      )}
    </div>
  );
};

export default BasicInfo;
