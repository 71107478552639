import React, { useEffect, useState } from "react";
import { CardImg, CardTitle } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { Link } from "react-router-dom";
import { formatDate, getCookie } from "../../utils/Utils";
import Upcoming_events from "../../../src/assets/images/Upcoming events.png"

// let token = process.env.REACT_APP_AUTH_TOKEN;

const UpcomingEvents = () => {
  const [eventdata, setEventData] = useState([]);
  const [isEventPresent, setIsEventPresent] = useState(true);

  useEffect(() => {
    eventInfo();
    return () => {};
  }, []);
  const eventInfo = async () => {
    try {
      const formData = new FormData();
      formData.append("categorize_event", "upcoming");
      formData.append("limit", "5");
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/events/get-event`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: formData,
      });
      if (!response?.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      if (responseData.status === false) {
        setIsEventPresent(false);
        return null;
      }
      setEventData(responseData ? responseData?.data : []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <h4 className="me-2">Upcoming events</h4>{" "}
              <Link to={`${process.env.PUBLIC_URL}event-list`} className="link d-block d-md-inline ">
               History
              </Link>
            </h6>
          </CardTitle>
        </div>
      </div>
      <DataTableBody className="border-top" bodyclass="nk-tb-orders">
        {eventdata.length !== 0 ? (
          <>
            <DataTableHead>
              <DataTableRow>
                <span className="text-dark">Sr. No.</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">Client Name</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">Event Name</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">Event Type</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">Event Date</span>
              </DataTableRow>
              <DataTableRow>
                <span className="text-dark">Venue Name</span>
              </DataTableRow>
            </DataTableHead>
            {isEventPresent &&
              Array.isArray(eventdata) && eventdata.map((item, idx) => {
                return (
                  <DataTableItem key={idx}>
                    <DataTableRow>
                      <span className="tb-lead">
                        <a href="#order" className="text-dark">{idx + 1}</a>
                      </span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub text-dark" >{item?.client_details?.[0]?.first_name}</span>
                      <span className="tb-sub px-1 text-dark " >{item?.client_details?.[0]?.last_name}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub text-dark">{item?.event_name}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub text-dark">{item?.event_type_name}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub tb-amount text-dark">{formatDate(item?.start_date_time)}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="tb-sub tb-amount text-dark">
                        {/* {item?.venue_details?.[0]?.city} , {item?.venue_details?.[0]?.state} */}
                        {item?.venue_details?.[0]?.venue_name}
                      </span>
                    </DataTableRow>
                  </DataTableItem>
                );
              })}
          </>
        ) : (
          // <div className="fs-3 justify-center align-center h-100px">
          //   <span>No Records Present</span>
          // </div>
          <div className="text-body text-center m-3">
        {/* // <div className="text-body">No events found.</div> */}
        <CardImg

        src={Upcoming_events}
        alt="avatar"
        style={{ width: "15%", height: "15%", objectFit: "cover" }}
      />
      </div>
        )}
      </DataTableBody>
    </React.Fragment>
  );
};
export default UpcomingEvents;
