import React from "react";
import { Card, Col, Row } from "reactstrap";
import NavigationHeader from "../components/NavigationHeader";
import { useLocation } from "react-router";
import { getClientsList } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";
import profileimg from "../../assets/images/profileimg.png";

function PreviewClient() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const from = searchParams.get("from");
  const page = searchParams.get("page");
  
  const {
    data: clientsList,
    isLoading: clientsListIsLoading,
    isError: clientsListIsError,
  } = useQuery({
    queryKey: ["get-clients-by-id", id],
    queryFn: () => getClientsList({ id: id }),
    // onSuccess:()=>{
    //   queryClient.invalidateQueries("get-clients-by-id", id);
    // },
    staleTime: 5000,
    cacheTime: 300000,
    retry: 1,
  });



  const client = clientsList?.data?.clients?.[0];

  const clientTypeOptions = [
    { value: "1", label: "Individual" },
    { value: "2", label: "Company" },
    { value: "3", label: "Organization" },
    { value: "4", label: "School" },
  ];

  const clientTypeLabel = clientTypeOptions?.find(
    (option) => option?.value === client?.client_type
  )?.label || "Unknown";

  const handleImageError = (e) => {
    e.target.src = profileimg; // Set fallback image if userProfile image fails to load
  };

  return (
    <>
      <NavigationHeader
        heading={from === "previewdetail" ? "Client Details" : ""}
        buttonInnerText={"Back"}
        route={"/client-list"}
        back
      />
      {clientsListIsLoading ? (
        <Row className="py-0 m-4">
          {/* Left column with image */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col md={4} className="text-start mt-2">
                {/* Image */}
                <ShimmerThumbnail height={150} width={150} rounded={false} />
              </Col>
              <Col md={8} className="g-4">
                <Row className="mt-2">
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-start" md={5}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                  <Col className="text-start" md={7}>
                    <ShimmerText height={20} width={150} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {/* Right column with details */}
          <Col md={6} className="border p-3 bg-white g-3">
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
            <Row>
              <Col className="text-start" md={5}>
                <ShimmerText height={20} width={150} />
              </Col>
              <Col className="text-start" md={7}>
                <ShimmerText height={20} width={150} />
              </Col>
            </Row>
              <ShimmerText height={20} width={150} />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="py-0 m-4">
            {/* Left column with image */}
            <Col md={6} className="border p-3 bg-white g-3">
              <Row>
                <Col md={4} className="text-start mt-2">
                  {/* Image */}
                  <img
                    src={client?.profile_image_path || profileimg} // Replace with the actual image field
                    alt="Client Image"
                    onError={handleImageError}
                    style={{ width: "150px", height: "150px", borderRadius: "0%", objectFit: "cover" }}
                  />
                </Col>
                <Col xs={12} md={8} className="g-4">
                  <Row className="mt-2">
                    <Col xs={6} className="text-start" md={5}>
                      <strong>First Name :</strong>
                    </Col>
                    <Col xs={6} className="text-start" md={7}>
                      {client?.first_name || "N/A"}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-start" xs={6} md={5}>
                      <strong>Last Name :</strong>
                    </Col>
                    <Col className="text-start" xs={6} md={7}>
                      {client?.last_name || "N/A"}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} md={5}>
                      <strong>Address Line 1 :</strong>
                    </Col>
                    <Col xs={6} md={7}>
                      {client?.address_1 || "N/A"}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={5}>
                      <strong>Address Line 2 :</strong>
                    </Col>
                    <Col xs={6} md={7}>
                      {client?.address_2 || "N/A"}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={5}>
                      <strong>Zip-code :</strong>
                    </Col>
                    <Col xs={6} md={7}>
                      {client?.zip_code || "N/A"}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={5}>
                      <strong>Country :</strong>
                    </Col>
                    <Col xs={6} md={7}>
                      {client?.country_name || "N/A"}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={5}>
                      <strong>State :</strong>
                    </Col>
                    <Col xs={6} md={7}>
                      {/* {client?.state || "N/A"} */}
                      {client?.stateName || "N/A"}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={5}>
                      <strong>City :</strong>
                    </Col>
                    <Col xs={6} md={7}>
                      {/* {client?.city || "N/A"} */}
                      {client?.cityName || "N/A"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* Right column with details */}
            <Col xs={12} md={6} className="border p-3 bg-white g-3">
              <Row>
                <Col xs={6} md={4}>
                  <strong>Email :</strong>
                </Col>
                <Col xs={6} md={8} className="text-break">
                  {client?.email || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Username :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.username || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Client Type :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {clientTypeLabel || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <strong>Company Details :</strong>
                </Col>
                <Col xs={6} md={8}>
                  {client?.company_detail || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col className="text-start" xs={6} md={4}>
                  <strong>Organization Name :</strong>
                </Col>
                <Col className="text-start" xs={6} md={8}>
                  {client?.organization_name || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col className="text-start" xs={6} md={4}>
                  <strong>Office Mobile Number :</strong>
                </Col>
                <Col className="text-start" xs={6} md={8}>
                  {client?.work_phone_number || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col className="text-start" xs={6} md={4}>
                  <strong>Personal Mobile Number :</strong>
                </Col>
                <Col className="text-start" xs={6} md={8}>
                  {client?.phone_number || "N/A"}
                </Col>
              </Row>
              <Row>
                <Col className="text-start" xs={6} md={4}>
                  <strong>Note :</strong>
                </Col>
                <Col className="text-start" xs={6} md={8}>
                  {client?.notes || "N/A"}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default PreviewClient;
